import React, { useEffect, useState } from "react";
import { Grid, Button, Card, CardContent, TableCell ,TableRow} from "@mui/material";
import { useDispatch } from "react-redux";
import { updateuserdata } from "../service";
import { enumReducerType } from "../common/enumReducerTypes";
import { useNavigate } from "react-router-dom";
import "./organism.css";
import { Callto } from "../common/Callto";
import { Mailto } from "../common/Emailto";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ITEM_HEIGHT = 48;

export const AllUsersItem = ({ item, options, selectedoption, getData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [statuslabel, setStatusLabel] = useState("");
  console.log(statuslabel, "statuslabel");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const currSelectedOpt = options.filter(
    (curr) => selectedoption === curr.value
  )[0];

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleAccept = (item) => {
    console.log("item data:" + item);
    const type = enumReducerType.Curr_User_Data;
    const payload = item;
    const action = {
      type,
      payload,
    };

    dispatch(action);
    navigate("/addsubs");
  };

  const handleChangeOpt = async (elem) => {
    console.log(elem, "elem--");
    const id = item.userid;
    const statusid = elem;
    const data = {
      statusid,
    };
    const userPayload = {
      id,
      data,
    };
    try {
      const result = await updateuserdata(userPayload);
      handleClose();
    } catch (e) {
      console.log("error while update status-->", e);
    }
  };
  useEffect(() => {
    const result = options
      .filter((elem) => elem.value === item.statusid)
      .map((item) => item.label);
    setStatusLabel(result);
  }, [options]);
  return (
    
    <TableRow
    style={{
      marginTop: "10px",
      marginBottom: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    }}
    key={item.name}
    sx={{
      "&:last-child td, &:last-child th": { border: 0 },
    }}
  >
    <TableCell component="th" scope="row" align="center">
      {item.name}
    </TableCell>
    <TableCell align="center">+91 {item.mobile}</TableCell>
    <TableCell align="center">{item.email}</TableCell>
    <TableCell align="left">
    {statuslabel}
    <IconButton
                sx={{ float: "right" }}
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "20ch",
                  },
                }}
              >
                {options.map((item) => (
                  <MenuItem
                    key={item}
                    selected={item.label === "New"}
                    onClick={() => handleChangeOpt(item.value)}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Menu>

    </TableCell>
    <TableCell align="center">
      {" "}
      <Button
                onClick={() => handleAccept(item)}
                variant="contained"
                className="button-all-clr"
              >
                submit
              </Button>
    </TableCell>
  </TableRow>
  );
};
