import React, { useState } from "react";
import { allbiz, getallsubscriber } from "../service";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, Button } from "@mui/material";
import { alltrans } from "../service/addtransaction/alltrans";

export const ReportBizItem = () => {
  const [bizname, setBizname] = useState("");
  const [filteredbizname, setFilteredbizname] = useState([]);
  const [enrolldate, setEnrolldate] = useState([]);
  const [Name, setName] = useState("");
  const [filtereddata, setFiltereddata] = useState([]);
  const [bizlistdata, setBizlistdata] = useState([]);
  const [ALLBIZ, setALLBIZ] = useState([]);
  const [allsubsdata, setAllsubsdata] = useState([]);
  const [data, setData] = useState([]);
  const [subsid, setSubsid] = useState("");
  const [selbiz, setSelbiz] = useState([]);

  //REDUCER DATA
  // const subscriberid = useSelector((state) => state.reportreducer.subid);
  const subscriberid = "5923ef581deb466a8d5d6e80c408d9e9";
  setSubsid(subscriberid);
  // setSubsid("5923ef581deb466a8d5d6e80c408d9e9");
  // const selectedbiz = useSelector((state) => state.reportreducer.rptbiz);
  // setSelbiz(selectedbiz);

  console.log("All Business Data====>", ALLBIZ);
  //GET ALL SUBSCRIBER  & BUSINESS DATA
  const getdata = async () => {
    alert("SUBID==>", subscriberid);
    const allsubs = await getallsubscriber();
    const allbizness = await allbiz();
    setAllsubsdata(allsubs);
    console.log("All Subscriber Data====>", allsubsdata);

    setALLBIZ(allbizness);
    console.log("All Business Data====>", ALLBIZ);

    const subsdata = allsubsdata.filter((item) => item.subsid === subsid);
    setFiltereddata(subsdata);
    console.log("Filtereddata==>", filtereddata);
    filtereddata.map((item) => setBizlistdata(item.bizs));
    console.log("BIZLISTdata==>", bizlistdata);
    const gotdata = bizlistdata.map((item) => setDataSelected(item));
    setData(gotdata, ...data);

    // mapbiz();
  };
  console.log("FinalData==>", data);
  // const allbizness = allbiz();
  // setALLBIZ(allbizness);

  // const getdata = async () => {
  //   const allsubs = await getallsubscriber();
  //   setAllsubsdata(allsubs);
  //   console.log("All Subscriber Data====>", allsubsdata);
  //   const allbizness = await allbiz();
  //   setALLBIZ(allbizness);
  //   console.log("All Business Data====>", ALLBIZ);
  //   // mapbiz();
  // };

  // const getbizname = async (bizid) => {
  //   bizlistdata.map(() => {
  //     const BIZNAME = ALLBIZ.filter((item) => item._id === bizid);
  //     setFilteredbizname(...BIZNAME, BIZNAME);
  //     console.log("Filteredbizname==>", filteredbizname);
  //     filteredbizname.map((item) => {
  //       setBizname(item.name);
  //       console.log("BIZNAME===>", bizname);
  //       alert("BIZNAME===>", bizname);
  //       return bizname;
  //     });
  //   });
  // };
  //ACCOUNT TRANSACTION DETAILS ACCORDING TO BIZID AND SUBSID
  const setDataSelected = async (bizid) => {
    // getbizname(bizid);
    // displaydata(bizid);
    const alltransaction = await alltrans(subsid, bizid);
    console.log("All Transaction==--==>", alltransaction);
    setData(alltransaction);
  };

  //GET NAME AND ENROLLMENTDATE OF SUBSID

  //GETBIZNAMES OF ALL BIZID
  // const mapbiz = () =>
  //   selbiz.map((item) => {
  //     setSubsid(subscriberid);
  //     setSelbiz();
  //     const BIZNAMES = getbizname(item);
  //     setBizname(BIZNAMES);
  //     //   setBizname(...BIZNAMES, bizname);
  //     //   setBizname(bizname);
  //     console.log("BIZNAMES===>", bizname);
  //     getotherdata();
  //   });

  useEffect(() => {
    // getdata();
    // mapbiz();
  }, []);

  return (
    <>
      {/* {selbiz &&
        filtereddata.map((item) => (
          <div>
            <Button
              variant="contained"
              fullWidth
              onClick={() => setDataSelected(item)}
            >
              {" "}
              {item}
            </Button>
          </div>
        ))} */}
      <h1>REPORT BIZ ITEM</h1>
      {data.acctransactions &&
        data.acctransactions.map((item, index) => (
          <>
            <Grid
              key={index}
              container
              spacing={1}
              style={{
                textAlign: "center",
                padding: "10px",
                backgroundColor: "#eff7fe",
                // fontWeight: "bold",
                padding: "20px",
                marginTop: "50px",
                marginLeft: "-10px",
              }}
              className="accdetails"
            >
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <b>{Name}</b>
              </Grid>{" "}
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <span>
                  <b>{bizname}</b>
                </span>{" "}
              </Grid>
              <Grid item lg={2} md={2} sm={6} xs={6}>
                <span>
                  <b>Enrollment Date:</b>
                </span>{" "}
                {/* {enrolldate.toLocaleDateString()} */}
                {enrolldate.toString().slice(0, 10)}
              </Grid>
              <Grid item lg={2} md={2} sm={6} xs={6}>
                <span>
                  :<b>Paid Amount</b>
                </span>{" "}
                {item.amount}
              </Grid>
            </Grid>
          </>
        ))}{" "}
      <Grid container spacing={2} className="accsummary">
        <Grid item xs={12} sm={6} md={4} lg={4} className="accdetails">
          <b>Credit:</b>
          <b> {data.accsummary && data.accsummary.totalCredits}</b>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} className="accdetails">
          <b>Debit:</b>
          <b> {data.accsummary && data.accsummary.totalDebits}</b>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} className="accdetails">
          <b>Balance:</b>
          <b> {data.accsummary && data.accsummary.totalBalance}</b>
        </Grid>
      </Grid>
      {/* <div> Biz id:{bizid}</div> */}
      <div> Subs id:{subsid}</div>
      <div> Subscriber Name:{Name}</div>
      <div> Enroll Date:{enrolldate}</div>
      <div> Biz Name:{bizname}</div>;
    </>
  );
};
