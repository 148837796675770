import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  TableRow,
  Paper,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Pagination,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AllRegistrationItem } from "./AllRegistrationItem";
import { getallsubscriber } from "../service";
import SearchIcon from "@mui/icons-material/Search";

export const AllRegistrations = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [origdata, setOrigdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const getData = async () => {
    const result = await getallsubscriber();
    console.log("=====Subscriber Details======>>", result);
    setData(result);
    setOrigdata(result);
  };

  const handleSearch = (searchtext) => {
    const filtereddata = origdata.filter(
      (item) =>
        (item.name &&
          item.name.toLowerCase().includes(searchtext.toLowerCase())) ||
        (item.mobile && item.mobile.toString().includes(searchtext)) ||
        (item.addressline &&
          item.addressline.toUpperCase().includes(searchtext.toUpperCase()))
    );
    setData(filtereddata);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const productsPerPage = 5;
  const totalPages = Math.ceil(data.length / productsPerPage);
  const startIndex = (currentPage - 1) * productsPerPage;
  const endIndex = startIndex + productsPerPage;
  const Data = data.slice(startIndex, endIndex);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    !token && navigate("/signin");
    getData();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
        <TextField
          variant="outlined"
          placeholder="Search Subscribers"
          fullWidth
          size="small"
          onChange={(e) => handleSearch(e.target.value)}
        />{" "}
      </Grid>{" "}
      <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
        <Button
          className="button-all-clr"
          fullWidth
          startIcon={<SearchIcon />}
          variant="contained"
        >
          Search
        </Button>
      </Grid>{" "}
      <Grid item xl={1} lg={1} md={1} sm={6} xs={6}>
        <Button className="button-all-clr-outline" variant="outlined">
          reset
        </Button>
      </Grid>{" "}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        {" "}
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}
        >
          <Table
            sx={{
              minWidth: 650,
              borderCollapse: "separate",
              borderSpacing: "0px 4px",
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <TableCell className="content-tabel-head" align="center">
                  Name
                </TableCell>
                <TableCell className="content-tabel-head" align="center">
                  Mobile Number
                </TableCell>
                <TableCell
                  className="content-tabel-head"
                  align="center"
                ></TableCell>
                <TableCell className="content-tabel-head" align="center">
                  Address
                </TableCell>
                <TableCell className="content-tabel-head" align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {Data.map((item) => (
                <AllRegistrationItem item={item} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid
          spacing={3}
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}></Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Stack spacing={2}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="secondary"
                variant="outlined"
              />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
