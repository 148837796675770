import React from "react";
import { Button, TableRow, TableCell } from "@mui/material";
import { useDispatch } from "react-redux";
import { enumReducerType } from "../common/enumReducerTypes";
import { useNavigate } from "react-router-dom";
import "./organism.css";

export const AllRegistrationItem = ({ item }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubs = (currItem) => {
    const type = enumReducerType.CURR_SELECTED_SUBSCRIBER;
    const payload = currItem;
    const action = { type, payload };
    dispatch(action);
    navigate("/alltrans");
    sessionStorage.setItem("currItem", JSON.stringify(currItem));
  };

  return (
    <TableRow
      style={{
        marginTop: "10px",
        marginBottom: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
      key={item.name}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell component="th" scope="row" align="center">
        {item.name}
      </TableCell>
      <TableCell align="center">+91 {item.mobile}</TableCell>
      <TableCell align="center"></TableCell>
      <TableCell align="left">{item.addressline}</TableCell>
      <TableCell align="center">
        {" "}
        <Button
          className="button-all-clr"
          onClick={() => handleSubs(item)}
          variant="contained"
          sx={{ width: "150px" }}
        >
          Pay
        </Button>{" "}
      </TableCell>
    </TableRow>
  );
};
