import React from 'react';
import AllCompTab from "./AllCompTab"

export const Home = () => {
  return (
    <div>
      <AllCompTab/>
    </div>
  );
};
