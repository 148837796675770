import React, { useState, useEffect } from "react";
import { Grid, TextField, Button, Alert } from "@mui/material";
import Select from "react-select";
import { useSelector } from "react-redux";
// import { parentsubsid } from "../config/common";
import { allbiz } from "../service";
import { Navigate, useNavigate } from "react-router-dom";

export const UserLinkGenerator = () => {
  const [role, setRole] = useState("");
  const [msg, setMsg] = useState("");
  const [msgErr, setMsgErr] = useState("");
  const [link, setLink] = useState("");
  const [bizlist, setBizlist] = useState([]);
  const navigate = useNavigate();
  // const bizdata = useSelector((state) => state.bizReducer.bizs);
  const parentsubsid = sessionStorage.getItem("parentsubsid");
  const [selectedbiz, setSelectedbiz] = useState({});

  const generatelink = () => {
    const link = `https://api.pickupbiz.in/usersignup?prntid=${parentsubsid}&&remarks=${selectedbiz.value}`;
    setLink(link);
  };

  // const getData = () => {
  //   const bizs = bizdata.map((item) => ({ value: item._id, label: item.name }));
  //   // bizs.push(selectedbiz);
  //   setBizlist(bizs);
  // };

  // useEffect(() => {
  //   getData();
  // }, []);

  const getbiz = async () => {
    let bizdata = await allbiz();
    console.log("Biz======----=======>" + bizdata);
    const bizs = bizdata.map((item) => ({ value: item._id, label: item.name }));

    setBizlist(bizs);
  };

  useEffect(() => {
    getbiz();
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={3}></Grid>
        <Grid item xs={12} md={6} sm={12}>
          <span className="innertext">Select Biz</span>{" "}
          {bizlist && (
            <Select
              style={{ margin: "10px !important" }}
              className="liftup"
              fullWidth
              options={bizlist}
              // defaultValue={selectedbiz}
              onChange={setSelectedbiz}
            />
          )}
        </Grid>
        <Grid item md={3}></Grid>

        {/* <Grid item md={3}></Grid>
          <Grid item xs={12} md={6} sm={12}>
            <TextField
              variant="outlined"
              fullWidth
              id="name"
              name="name"
              label="Parentsubid"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item md={3}></Grid> */}

        <Grid item md={3}></Grid>

        <Grid item xs={12} md={3} sm={12}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            onClick={generatelink}
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={12} md={3} sm={12}>
          <Button variant="contained" fullWidth type="reset">
            Cancel
          </Button>
        </Grid>
      </Grid>

      <div className="addpadding10 addmargin10">
        {link && (
          <span>
            <h3>Generated Link</h3> {link}
          </span>
        )}
        {msg && <Alert severity="success">{msg}</Alert>}
        {msgErr && <Alert severity="error">{msgErr}</Alert>}
      </div>
    </div>
  );
};
