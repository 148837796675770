import React, { useState } from "react";
import { Grid, TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { addbiz } from "../service/biz/addbiz";

export const AddBiz = () => {
  const [name, setName] = useState("");
  const [basecharges, setBasecharges] = useState(0);
  const [discountinpercent, setDiscountinpercent] = useState(0);
  const [recurring, setRecurring] = useState(false);
  const [totaldays, setTotaldays] = useState(0);
  const [reminder1, setReminder1] = useState(0);
  const [reminder2, setReminder2] = useState(0);
  const [reminder3, setReminder3] = useState(0);
  const [selectedoption, setSelectedoption] = useState({
    value: "false",
    label: "False",
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const reset = () => {
    setName("");
    setBasecharges(0);
    setDiscountinpercent(0);
    setTotaldays(0);
    setReminder1(0);
    setReminder2(0);
    setReminder3(0);
  };

  const options = [
    { value: "true", label: "True" },
    { value: "false", label: "False" },
  ];

  const validateBizName = (name) => {
    setName(name.toUpperCase());
    setError("");
    return true;
  };
  const handleSubmit = async (e) => {
    await addbiz(
      name,
      basecharges,
      discountinpercent,
      recurring.value,
      totaldays,
      reminder1,
      reminder2,
      reminder3
    );
    navigate("/viewbusiness");
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} sm={12} className="error">
          {error}
        </Grid>
        <Grid item xs={12} md={6} sm={12}>
          <TextField
            id="biz"
            onChange={(e) => validateBizName(e.target.value)}
            variant="outlined"
            label="Name of Business"
            fullWidth
            value={name.toUpperCase()}
            required
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={12} md={6} sm={12}>
          <TextField
            id="basecharges"
            onChange={(e) => setBasecharges(e.target.value)}
            variant="outlined"
            label="Base Charges"
            fullWidth
            value={basecharges}
            required
            autoComplete="off"
            type="number"
          />
        </Grid>
        <Grid item xs={12} md={6} sm={12}>
          <TextField
            id="discountinpercent"
            onChange={(e) => setDiscountinpercent(e.target.value)}
            variant="outlined"
            label="Discount in Percentage(%)"
            fullWidth
            value={discountinpercent}
            required
            autoComplete="off"
            type="number"
          />
        </Grid>
        <Grid item xs={12} md={6} sm={12} className="liftup">
          <label>
            <span className="smallfont">Recurring</span>
          </label>
          {options && (
            <Select
              options={options}
              defaultValue={selectedoption}
              onChange={(e) => setSelectedoption(e)}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} sm={12}>
          <TextField
            id="totaldays"
            onChange={(e) => setTotaldays(e.target.value)}
            variant="outlined"
            label="Total Days"
            fullWidth
            value={totaldays}
            required
            autoComplete="off"
            type="number"
          />
        </Grid>
        <Grid item xs={12} md={6} sm={12}>
          <TextField
            id="reminder1"
            onChange={(e) => setReminder1(e.target.value)}
            variant="outlined"
            label="Reminder 1"
            fullWidth
            value={reminder1}
            required
            autoComplete="off"
            type="number"
          />
        </Grid>
        <Grid item xs={12} md={6} sm={12}>
          <TextField
            id="reminder2"
            onChange={(e) => setReminder2(e.target.value)}
            variant="outlined"
            label="Reminder 2"
            fullWidth
            value={reminder2}
            required
            autoComplete="off"
            type="number"
          />
        </Grid>
        <Grid item xs={12} md={6} sm={12}>
          <TextField
            id="reminder3"
            onChange={(e) => setReminder3(e.target.value)}
            variant="outlined"
            label="Reminder 3"
            fullWidth
            value={reminder3}
            required
            autoComplete="off"
            type="number"
          />
        </Grid>

        <Grid item xs={12} md={6} sm={12}>
          <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            disabled={
              !name ||
              !basecharges ||
              !totaldays ||
              !reminder1 ||
              !reminder2 ||
              !reminder3
            }
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={12} md={6} sm={12}>
          <Button variant="contained" fullWidth onClick={reset}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
