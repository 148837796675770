import React from "react";
import { Link } from "react-router-dom";
import {
  ListItemIcon,
  ListItemButton,
  ListItemText,
  ListItem,
} from "@mui/material";

export const Navitem = ({ handleDrawerClose, text }) => {
  return (
    <ListItem onClick={handleDrawerClose} key={text} disablePadding>
      <Link style={{ textDecoration: "none" }} to={text.path}>
        <ListItemButton>
          <ListItemIcon>{text.BarIcon}</ListItemIcon>
          <ListItemText
            sx={{ textDecoration: "none", fontSize: "30px" }}
            primary={text.name.toUpperCase()}
          />
        </ListItemButton>
      </Link>
    </ListItem>
  );
};
