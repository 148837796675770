import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./atom/atom.css";
import "./molecules/Molecules.css";
import { configStore } from "./State/Store/configStore";
import { Provider } from "react-redux";
import { RoutingManager } from "./organisms/RoutingManager";
import { SignIn } from "./organisms/SignIn";

function App() {
  const myStore = configStore();
  return (
    
      <Provider store={myStore}>
        {/* <BrowserRouter style={{ backgroundImage: "url('bgimgDG.png')" }}> */}
        <BrowserRouter>
          <RoutingManager />
        </BrowserRouter>
        {/* <SignIn /> */}
      </Provider>
  );
}

export default App;
