import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import "./PieChartReport.css"; // Import CSS file for styling
import { isEmpty } from "../../common/reports";
import { getusersrptchart } from "../../service";

ChartJS.register(ArcElement, Tooltip, Legend);

export function PieChartReport({ payloaddata }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const resData = await getusersrptchart(payloaddata);
        console.log("--------resData------------>", resData);
        setData(resData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [payloaddata]);

  return (
    <div className="pie-chart-container">
      {loading ? (
        <div className="loader"></div>
      ) : isEmpty(data) ? (
        <h1>No Data</h1>
      ) : (
        <Pie data={data} className="pie-chart" />
      )}
    </div>
  );
}
