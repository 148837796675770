import React, { useEffect, useState } from "react";
import { allcurr } from "../service/currency/allcurr";
import { NestedListCurr } from "./NestedListCurr";

export const AllCurrency = () => {
  const [data, setData] = useState([]);

  const getcurr = async () => {
    let currdata = await allcurr();
    console.log("Currency======----=======>" + currdata);

    setData(currdata);

    console.log("Data========>" + data);
  };

  useEffect(() => {
    getcurr();
  }, []);
  return (
    <div>
      <h3>ALL CURRENCY </h3>
      <br />
      <NestedListCurr data={data} />
    </div>
  );
};
