import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const SignOut = () => {
  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.removeItem("token");
    navigate("/signin");
  }, []);
  return <div></div>;
};
