import { Grid, Card, CardContent, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  allbiz,
  updatesubscriber,
  getSubscriberBySubsid,
  getallsubscriberDD,
} from "../service";
import Checkbox from "@mui/material/Checkbox";
import { enumReducerType } from "../common/enumReducerTypes";
import { useDispatch } from "react-redux";

export const UpdateBiz = () => {
  const dispatch = useDispatch();

  const [options, setOptions] = useState([]);
  const [subiddata, setSubiddata] = useState("");
  const [subsid, setSubsid] = useState("");
  const [allbizs, setallBizs] = useState([]);
  const [bizs, setBizs] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const getDropsApi = async () => {
    const getSubdd = await getallsubscriberDD();
    setOptions(getSubdd);
  };
  const getAllbiz = async () => {
    const bizdata = await allbiz();
    setallBizs(bizdata);
  };
  const handleChangeAllSub = (e) => {
    setSubsid(e.value);
    getSubscribeById(e.value);
  };

  const getSubscribeById = async (subscriberid) => {
    const result = await getSubscriberBySubsid(subscriberid);
    setSubiddata(result);
  };

  const handleBizs = (item, e) => {
    const type = enumReducerType.CURR_SELECTED_SUBSCRIBER;
    const payload = item;
    const action = {
      type,
      payload,
    };
    dispatch(action);
    const selvalue = e.target.checked;
    if (selvalue) {
      setBizs([item, ...bizs]);
    } else {
      const filtered = bizs.filter((curr) => !(curr._id === item._id));
      setBizs(filtered);
    }
  };

  const handleUpdateBiz = async () => {
      const payload = {
        subsid,
        bizs: [...bizs, ...subiddata.bizs],
      }
      const response = await updatesubscriber(payload)
      response !==null ? setTimeout(() => {
        console.log('Success')
        getAllbiz()
        setForceUpdate(true)
      }, 5000):console.log('Errorr')
  };
  // 7058657111

  useEffect(() => {
    getDropsApi();
    getAllbiz();
  }, []);
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Select options={options} onChange={(e) => handleChangeAllSub(e)} />
          <br />
          {subiddata.length === 0 ? (
            <Grid textAlign="center" item xl={6} lg={6} md={6} sm={12} xs={12}>
              <h2 style={{ color: "#7B7B7B" }}>Please Select Name !!!</h2>
            </Grid>
          ) : (
            <Card>
              <CardContent>
                <h1> {subiddata.name} </h1>
                <p> {subiddata.email} </p>
                <p>
                  {" "}
                  {Number(subiddata.mobile.toString().slice(0, -4)) + "****"}
                </p>
                <p>
                  {subiddata.addresslocation}, {subiddata.addressline}{" "}
                </p>
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          {subiddata.length !== 0 && (
            <Card sx={{ height: 430, overflow: "auto" }} key={forceUpdate}>
              <CardContent>
                {allbizs.map((item) => (
                  <React.Fragment>
                    <Grid container spacing={2} className="gridrow">
                      <Grid item xs={1} md={1} sm={1} lg={1}>
                        {subiddata &&
                          subiddata.bizs &&
                          !subiddata.bizs.includes(item._id) && (
                            <Checkbox
                              className="chkbox"
                              size="small"
                              value={item._id}
                              onChange={(e) => handleBizs(item, e)}
                            />
                          )}
                      </Grid>
                      <Grid item xs={5} md={5} sm={5} lg={5}>
                        <b>{item.name} </b>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={2}
                        sm={5}
                        lg={5}
                        className="subsdetails"
                      >
                        Rs.{item.basecharges}
                      </Grid>
                      <Grid item xs={1} md={1} sm={1} lg={1}></Grid>
                      <Grid item xs={1} md={1} sm={1} lg={1}></Grid>
                      <Grid
                        item
                        xs={5}
                        md={5}
                        sm={5}
                        lg={5}
                        className="subsdetails"
                      >
                        {item.totaldays} Days
                      </Grid>

                      <Grid
                        item
                        xs={4}
                        md={3}
                        sm={5}
                        lg={5}
                        className="subsdetails"
                      >
                        {item.recurring ? "Every Month" : "One Time"}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))}
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={handleUpdateBiz}
            variant="contained"
            sx={{ float: "right" }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
