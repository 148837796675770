import React from 'react';
import { Container, Typography, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const ThankYou = () => {
  const navigate = useNavigate();

  const handleGoToClose = () => {
    window.close();
  };

  return (
    <Container>
      <Grid container spacing={2} alignItems="center" justifyContent="center" direction="column" style={{ backgroundColor: '#f0f0f0', padding: '20px' }}>
        <Grid item>
          <img src="Images/PickupBizLearningBanner.png" alt="Thank You Image" style={{ width: '100%', marginTop: '10px' }} />
        </Grid>
        <Grid item>
          <Typography variant="h4" gutterBottom style={{ color: 'green', fontSize: '24px' }}>
            Thank you for registering at PickupBiz Learning! Please check your registered mailbox for your password details.
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleGoToClose} style={{ marginTop: '20px' }}>
            Close
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
