import React, { useState } from "react";
import { Grid, Button } from "@mui/material";

import { useDispatch } from "react-redux";
import { Close } from "@material-ui/icons";
import { enumReducerType } from "../common/enumReducerTypes";
import { useNavigate } from "react-router-dom";
import "./organism.css";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  IconButton,
  Typography,
} from "@material-ui/core";

export const AllRegistrationItemUpdateDelete = ({ item }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dialog, setDialog] = useState();
  const handleSubs = (currItem) => {
    const type = enumReducerType.CURR_SELECTED_SUBSCRIBER;
    const payload = currItem;
    const action = { type, payload };
    dispatch(action);
    navigate("/alltrans");
  };
  const handleUpdate = (currItem) => {
    const type = enumReducerType.CURR_SELECTED_SUBSCRIBER;
    const payload = currItem;
    const action = { type, payload };
    dispatch(action);
    navigate("/updatesubs");
  };

  const DeleteSubscriber = () => {
 //code for deleting subscriber expected

  };


  const handleDelete = (subsid) => {
    setDialog(
      <Dialog open={true} maxWidth="sm" fullWidth>
        <DialogTitle>Confirm the action</DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <IconButton>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography>Are you sure, you want to delete Subscriber ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={hideDialog}>
            No
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => DeleteSubscriber(item.subsid)}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );

    return Dialog;
  };

  const hideDialog = () => {
    setDialog("");
  };

  return (
    <Grid container spacing={2} className="gridrow">
      <Grid item xs={12} md={4} sm={12} lg={2}>
        <PersonIcon className="personicon" /> <b>{item.name} </b>
      </Grid>
      <Grid item xs={12} md={3} sm={12} lg={2}>
        {" "}
        <PhoneIcon className="phoneicon" />
        <b>
          {" "}
          <span className="userlabel"> Mobile: </span>
        </b>{" "}
        <a href={`tel:+${item.mobile}`}>{item.mobile}</a>
      </Grid>
      <Grid item xs={12} md={12} sm={12} lg={3}>
        {" "}
        <LocationOnIcon className="locationicon" />
        <span className="userlabel">
          {" "}
          <b>Address:</b>{" "}
        </span>
        {item.addressline}{" "}
      </Grid>
      <Grid item xs={12} md={12} sm={12} lg={1}>
        <Button
          className="btndark"
          onClick={() => handleSubs(item)}
          variant="contained"
          fullWidth 
        >
          Pay
        </Button>
      </Grid>
      <Grid item xs={12} md={12} sm={12} lg={2}>
        <Button
          className="btndark"
          onClick={() => handleUpdate(item)}
          variant="contained"
          fullWidth
        >
          Update
        </Button>
      </Grid>
      <Grid item xs={12} md={12} sm={12} lg={2}>
        <Button
          className="btndark"
          onClick={() => handleDelete(item.subsid)}
          variant="contained"
          fullWidth
        >
          Delete
        </Button>
      </Grid>
    </Grid>
  );
};
