import React, { useEffect, useState } from "react";
import { Grid, Button, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { allusers } from "../service/users/allusers";
import "./organism.css";
import SearchIcon from '@mui/icons-material/Search';
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";

export const StatusDashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [msgErr, setMsgErr] = useState("");
  const [status, setStatus] = useState("");

  const handleAll = async () => {
    setStatus("All");
    setMsgErr("");
    const alluser = await allusers();
    console.log(alluser);
    setData(alluser);
  };
  const handleStatus = async (status, st) => {
    setStatus(st);
    const alluser = await allusers();
    console.log("AllUsers", alluser);

    const result = alluser.filter((item) => item.statusid.includes(status));
    if (result.length > 0) {
      setData(result);
      console.log("Filtered Staus Users", result);
      setMsgErr("");
    } else {
      setMsgErr("No Users With This Status");
      setData([]);
    }
  };
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    !token && navigate("/signin");

    handleAll();
  }, []);

  return (
    <div>
      {" "}
      <Grid container spacing={2}>
        <Grid item xs={6} md={8} sm={6} lg={9}>
          <h3>Select Status </h3>{" "}
        </Grid>
        <Grid item xs={6} md={4} sm={6} lg={3}>
          <Button
            fullWidth
            variant="contained"
            className="advancebtn"
          endIcon={<SearchIcon/>}

            onClick={() => navigate("/reports")}
          >
            <span> Advance</span>
          </Button>
        </Grid>
        <Grid item xs={6} md={2} sm={6} lg={2}>
          <React.Fragment>
            <Button
              fullWidth
              className="btndark"
              variant="contained"
              onClick={() => handleAll("All")}
            >
              All
            </Button>
          </React.Fragment>
        </Grid>
        <Grid item xs={6} md={2} sm={6} lg={2}>
          <Button
            className="btndark"
            variant="contained"
            fullWidth
            onClick={() => handleStatus("649aad2aac92af02a4dc7fc6", "New")}
          >
            New
          </Button>
        </Grid>
        <Grid item xs={6} md={2} sm={6} lg={2}>
          <Button
            variant="contained"
            fullWidth
            className="btndark"
            onClick={() =>
              handleStatus("649aad42ac92af02a4dc7fcc", "In-Progress")
            }
          >
            In Progress
          </Button>
        </Grid>
        <Grid item xs={6} md={2} sm={6} lg={2}>
          <Button
            variant="contained"
            fullWidth
            className="btndark"
            onClick={() => handleStatus("649aad4aac92af02a4dc7fd2", "Active")}
          >
            Active
          </Button>
        </Grid>
        <Grid item xs={6} md={2} sm={6} lg={2}>
          <Button
            fullWidth
            variant="contained"
            className="btndark"
            onClick={() =>
              handleStatus("649aad53ac92af02a4dc7fd8", "De-Active")
            }
          >
            De-Active
          </Button>
        </Grid>
        <Grid item xs={6} md={2} sm={6} lg={2}>
          <Button
            fullWidth
            className="btndark"
            variant="contained"
            onClick={() =>
              handleStatus("649aad5dac92af02a4dc7fde", "Suspended")
            }
          >
            Suspended
          </Button>
        </Grid>
      </Grid>
      <br />
      <h3>
        All User Details With Status <span className="status">{status}</span>
      </h3>
      {data &&
        data.map((item) => (
          <Grid container spacing={2} className="gridrow" key={item.userid}>
            <Grid item xs={12} md={3} sm={12} lg={3.5}>
              <PersonIcon className="personicon" />
              <b>{item.name} </b>
            </Grid>
            <Grid item xs={12} md={3} sm={12} lg={4}>
              {" "}
              <b>
                {" "}
                <span className="userlabel">Mobile: </span>{" "}
              </b>{" "}
              <PhoneIcon className="phoneicon" /> {item.mobile}
            </Grid>
            <Grid item xs={12} md={6} sm={12} lg={4.5}>
              {" "}
              <span className="userlabel">
                {" "}
                <b>Email:</b>
              </span>{" "}
              <EmailIcon className="emailicon" />
              {item.email}
            </Grid>
          </Grid>
        ))}
      {msgErr && <Alert severity="error">{msgErr}</Alert>}
    </div>
  );
};
