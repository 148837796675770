import React, { useEffect, useState } from "react";
import { allcategory } from "../service/category/allcategory";
import { NestedListCategory } from "./NestedListCategory";
export const AllCategory = () => {
  const [data, setData] = useState([]);

  const getcategory = async () => {
    const data = await allcategory();
    console.log("Categories======----=======>" + data);
    setData(data);
  };

  useEffect(() => {
    getcategory();
  }, []);

  return (
    <div>
      <h3>ALL CATEGORY</h3>
      <NestedListCategory data={data} />
    </div>
  );
};
