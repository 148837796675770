import React, { useEffect, useState } from "react";

import { allstatus } from "../service/status/allstatus";
import { NestedListStatus } from "./NestedList";

export const AllStatus = () => {
  const [data, setData] = useState([]);

  const getstatus = async () => {
    const data = await allstatus();
    console.log("Status======----=======>" + data);
    setData(data);
  };

  useEffect(() => {
    getstatus();
  }, []);
  return (
    <div>
      <h3>All STATUS DETAILS</h3>
      <NestedListStatus data={data} />
    </div>
  );
};
