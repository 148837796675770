import { postSecureAPI } from "../helper";

export const addbiz = async (
  name,
  basecharges,
  discountinpercent,
  recurring,
  totaldays,
  reminder1,
  reminder2,
  reminder3
) => {
  const parentsubsid = sessionStorage.getItem("parentsubsid");
  const payload = {
    name,
    basecharges,
    discountinpercent,
    recurring,
    totaldays,
    reminder1,
    reminder2,
    reminder3,
    parentsubsid,
  };
  const result = await postSecureAPI("/addbiz", payload);
  return result;
};
