import { postSecureAPI } from "../helper";

export const alltrans = async (subsid, bizid) => {
  const parentsubsid = sessionStorage.getItem("parentsubsid");

  const payload = {
    subsid,
    parentsubsid,
    bizid,
  };
  const result = await postSecureAPI("/getalltransactions", payload);
  return result;
};
