import { enumReducerType } from "../../common/enumReducerTypes";
export const statusReducer = (
  state = {
    // status: {},
    status: [],
    //what should i right here above
  },
  action
) => {
  switch (action.type) {
    case enumReducerType.STATUS_DATA:
      return {
        ...state,
        status: action.payload,
      };
  }

  return state;
};
