import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SignUpDirect } from "./SignUpDirect";
// import { HomeInteface } from "./HomeInterface";
import { ThankYou } from "./Thankyou";
import { Navigation } from "./Navigation";


export const RoutingManager = () => {
  const [searchParams] = useSearchParams();
  console.log();
  const [isParentId] = useState(searchParams.get("prntid") !== null);
  const [parentsubsid] = useState(searchParams.get("prntid"));
  const [remarks] = useState(searchParams.get("remarks"));
  const [page] = useState(searchParams.get("page") === "thankyou");
  const [campaign] = useState(
    searchParams.get("campaign") !== null
      ? searchParams.get("campaign")
      : "PickupBizLearningBanner"
  );

  

  return (
    <React.Fragment>
      {page ? (
        <ThankYou />
      ) : isParentId ? (
        <SignUpDirect
          campaign={campaign}
          parentsubsid={parentsubsid}
          remarks={remarks}
        />
      ) : (
        <Navigation/>
      )}
    </React.Fragment>
  );
};
