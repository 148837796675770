import React, { useState } from "react";
import { Grid, TextField, Button, Alert } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import { addcurr } from "../service/currency/addcurr";

export const AddCurrency = () => {
  const [msg, setMsg] = useState("");
  const [msgErr, setMsgErr] = useState("");

  const validationSchema = Yup.object({
    code: Yup.string()
      .max(15, "Must be 15 characters or less")
      .required("Required *"),
    name: Yup.string()
      .min(3, "Must be 3 characters or more")
      .max(25, "Must be 25 characters or less")
      .required("Required *"),
    symbol: Yup.string()
      .max(5, "Must be 5 characters or less")
      .required("Required *"),
  });

  const postCurrency = async (values) => {
    try {
      addcurr(values);
      setMsg("Currency Added Successfully !!!");
      setMsgErr("");
    } catch (e) {
      setMsgErr(e.response.data.message);
      setMsg("");
    }
  };

  const formik = useFormik({
    initialValues: {
      code: "",
      name: "",
      symbol: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      try {
        postCurrency(values);
        resetForm({ values: "" });
      } catch (e) {
        console.log("-e----------->", e);
      }
    },
  });

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Grid container spacing={2}>
          <Grid item md={4}></Grid>
          <Grid item md={4} sm={12} xs={12}>
            <Grid item xs={12} md={12} sm={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="code"
                name="code"
                label="Add Code"
                value={formik.values.code}
                onChange={formik.handleChange}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
              />
            </Grid>
            <br />
            <Grid item xs={12} md={12} sm={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="name"
                name="name"
                label="Add Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <br />
            <Grid item xs={12} md={12} sm={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="symbol"
                name="symbol"
                label="Add Symbol"
                value={formik.values.symbol}
                onChange={formik.handleChange}
                error={formik.touched.symbol && Boolean(formik.errors.symbol)}
                helperText={formik.touched.symbol && formik.errors.symbol}
              />
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={6} md={6} sm={6} lg={6}>
                <Button variant="contained" fullWidth type="submit">
                  Submit
                </Button>
              </Grid>
              <Grid item xs={6} md={6} sm={6} lg={6}>
                <Button variant="contained" fullWidth type="reset">
                  Cancel
                </Button>
              </Grid>
            </Grid>{" "}
          </Grid>
          <Grid item md={4}></Grid>
        </Grid>

        <div className="addpadding10 addmargin10">
          {msg && <Alert severity="success">{msg}</Alert>}
          {msgErr && <Alert severity="error">{msgErr}</Alert>}
        </div>
      </form>
    </React.Fragment>
  );
};
