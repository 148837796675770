import { postAPI } from "../helper";

export const login = async (obj) => {
  const result = await postAPI("/auth/signin", obj);
  return result;
};

export const updateuserdata = async (payload) => {
  const result = await postAPI("/auth/updateuserdata", payload);
  return result;
};
