import * as React from "react";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  Grid,
} from "@mui/material";
import DnsIcon from "@mui/icons-material/Dns";

export function NestedListStatus({ data }) {
  const [open, setOpen] = React.useState(true);
  const [show, setShow] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const handllstebtn = (indx) => {
    setShow(true);
  };

  return (
    <List>
      {" "}
      {data.map((item, index) => (
        <Grid container spacing={2}>
          <>
            <Grid
              item
              md={3}
              xs={0.1}
              sm={0.1}
              lg={3}
              className="hidegrid"
            ></Grid>
            <Grid item md={6} xs={12} sm={12} lg={6}>
              <ListItemButton key={index} onClick={() => handllstebtn(index)}>
                <ListItemIcon>
                  <DnsIcon style={{ backgroundColor: "navyblue" }} />
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </Grid>
            <Grid
              item
              md={3}
              xs={0.1}
              sm={0.1}
              lg={3}
              className="hidegrid"
            ></Grid>
          </>
        </Grid>
      ))}
      {/* {show && <FormDialog />} */}
    </List>
  );
}
