import React, { useEffect, useState } from "react";
import { Grid, Button, selectClasses } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { alltrans } from "../service/addtransaction/alltrans";
import { allbiz, getallsubscriber } from "../service";
import "./organism.css";
import { Report } from "./Report";

export const ReportPerStudent = () => {
  const [options, setOptions] = useState([]);
  const [selectedoption, setSelectedoption] = useState(null);
  const [bizlistdata, setBizlistdata] = useState([]);
  const [biz, setBiz] = useState([]);

  const [filtereddata, setFiltereddata] = useState([]);
  const [ALLBIZ, setALLBIZ] = useState([]);
  const [allsubsdata, setAllsubsdata] = useState([]);
  const [transaction, setTransaction] = useState([]);
  const [report, setReport] = useState([]);
  const [subscriber, setSubscriber] = useState([]);
  const [final, setFinal] = useState([]);
  const reportdata = [];

  //Code for getting Subscriber name with id
  const getsubslist = async () => {
    try {
      const allsubs = await getallsubscriber();

      setAllsubsdata(allsubs);

      console.log("All Subscriber Data====>", allsubs);
      const subscriberlist = allsubsdata.map((item) => ({
        value: item.subsid,
        label: item.name,
      }));
      setOptions(subscriberlist);
    } catch (error) {
      console.log(error);
    }
  };

  const getdata2 = async (Subsid, Bizid, biz) => {
    await alltrans(Subsid, Bizid).then((data) => {
      setReport((prevReport) => {
        return [data, ...prevReport];
        // return [biz.concat(data), ...prevReport];
      });

      // const reportdata = report.map((item) => {
      //   return {
      //     Bizname: item.bizname,
      //     Paid_Date: item.acctransactions.createdDate,
      //     PMT_Mode: item.acctransactions.modeofpayment,
      //     trans_type: item.acctransactions.transactiontype,
      //     paid_amt: item.acctransactions.amount,
      //     credits: item.accsummary.totalCredits,
      //     debits: item.accsummary.totalDedits,
      //     balance: item.accsummary.totalBalance,
      //   };
      // });
      // reportdata.push(reportdata);
      // console.log("reportdtaobject", reportdata);
    });

    return report;
  };

  const getbizname = (bizid) => {
    const bizlist = ALLBIZ.filter((itema) => itema._id === bizid);
    bizlist.map((item) => {
      const biz = item.name;

      return biz;
    });
  };

  const alltransaction = (transaction) => {
    setReport((prevtrans) => {
      return [transaction, ...prevtrans];
    });
  };
  //On Change On Subscriber dropdown selection
  const handleSubs = async (subscriberid, subscribername) => {
    const allbizness = await allbiz();
    setALLBIZ(allbizness);

    console.log("All Business Data====>", ALLBIZ);
    setTransaction([]);
    setSelectedoption(subscriberid);

    setSubscriber(subscribername);
    console.log("FIRST COMBO subsid====>", subscriber);
    console.log("ALLSUBSDATA==>", allsubsdata);
    const subsdata = allsubsdata.filter((item) => item.subsid === subscriberid);
    setFiltereddata(subsdata);
    console.log("Filtereddata==>", filtereddata);
    filtereddata.map((item) => {
      console.log("Filtered Name===>", item.name);
      setBizlistdata(item.bizs);
    });
    console.log("BIZLISTDATA===>", bizlistdata);

    const dt1 = bizlistdata.map((item) => {
      const bizn = getbizname(item);
      const biz = { bizname: bizn };

      console.log("BIZ", biz);
      const transaction = getdata2(subscriberid, item, biz);
      const alltrans = alltransaction(transaction);
      return alltrans;
    });
    setFinal(dt1);
    setReport([final, ...report]);
    console.log("Report DATA", report);
  };

  useEffect(() => {
    getsubslist();
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2} sm={12} lg={2} className="liftup1 ">
          <b>Select Student</b>
        </Grid>
        <Grid item xs={12} md={6} sm={12} lg={4} className="liftup1 ">
          {options && (
            <Select
              isSearchable="true"
              options={options}
              onChange={(e) => handleSubs(e.value, e.label)}
            />
          )}
        </Grid>
        <Grid
          container
          spacing={1}
          style={{
            textAlign: "center",
            padding: "10px",
            backgroundColor: "#eff7fe",
            // fontWeight: "bold",
            padding: "20px",
            marginTop: "10px",
            marginLeft: "-10px",
          }}
          className="accdetails"
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              textAlign: "left",
              padding: "10px",
            }}
          >
            <b>{subscriber}</b>
            <Grid>
              <Report report={report} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
