import React, { useState, useEffect } from "react";
import { Grid, Button, Card, CardContent, Typography } from "@mui/material";
import { Field, Formik } from "formik";
import { Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { signup } from "../service";
import "./organism.css";
import Select from "react-select";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import { allqualification } from "../service";
import { allyears } from "../service";
import { InputFields } from "../molecules/InputFields";

export const SignUp = () => {
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState("");
  const [msgErr, setMsgErr] = useState("");
  const [qualification, setQualification] = useState();
  const [selectedQulaification, setSelectedQualification] = useState();
  const [years, setYearlist] = useState();
  const [selectedYear, setSelYear] = useState();
  const navigate = useNavigate();

  const getqualificationList = async () => {
    let qualidata = await allqualification();
    console.log("Qulaification======----=======>" + qualidata);
    const qualis = qualidata.map((item) => ({
      value: item.value,
      label: item.label,
    }));
    setQualification(qualis);
    setSelectedQualification(qualis[0]);
  };

  const getyears = async () => {
    let yearsdata = await allyears();
    console.log("Years======----=======>" + yearsdata);
    const yearslist = yearsdata.map((item) => ({
      value: item.value,
      label: item.label,
    }));
    setYearlist(yearslist);
    setSelYear(yearslist[0]);
  };

  const handleYear = (e) => {
    setSelYear(e.value);
  };
  const handleQualification = (e) => {
    setSelectedQualification(e.value);
  };

  useEffect(() => {
    getyears();
    getqualificationList();
  }, []);

  const phonenumber =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\]?[0-9]{3}[-\s\]?[0-9]{4,6}$/im;
  const validate = Yup.object({
    name: Yup.string()
      .required("Required *")
      .matches(/^[A-Za-z\s]+$/, "Name can only contain letters and spaces"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    mobile: Yup.string()
      .min(10, "Enter valid mobile number")
      .required("Phone number is required *")
      .matches(phonenumber, "Invalid mobile number"),
  });
  const postUser = async (values) => {
    try {
      const parentsubsid = sessionStorage.getItem("parentsubsid");
      const remarks = "Registration by Admin";
      await signup({ ...values, parentsubsid, remarks });
      setMsg(
        "Thank you for your Registration !!! Please check your registered mailbox for your password details."
      );
      setMsgErr("");
      setShow(true);
      navigate("/allusers");
    } catch (e) {
      setMsgErr(e.response.data.message);
      setMsg("");
    }
  };

  return (
    <Grid container spacing={2} sx={{ height: "100%", minHeight: "85vh" }}>
      <Grid xl={4} lg={4} md={4} sm={12} xs={12}>
        {" "}
      </Grid>
      <Grid xl={4} lg={4} md={4} sm={12} xs={12}>
        <Formik
          initialValues={{ name: "", email: "", mobile: "" }}
          validationSchema={validate}
        >
          {(formik) => (
            <Form>
              <Grid item xs={12}>
                <Typography>
                  For More Information Please{" "}
                  <span>
                    <span>
                      <a href="../Images/PickupBiz Leaflet.pdf">Download</a>{" "}
                      <SaveAltOutlinedIcon />{" "}
                    </span>
                  </span>
                  Our Leaflet
                </Typography>
              </Grid>
              <Card>
                <CardContent>
                  <Grid container spacing={2} align="center">
                    <Grid item xs={12}>
                      <InputFields
                        size="small"
                        label="Full Name"
                        name="name"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputFields
                        size="small"
                        label="Email"
                        name="email"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputFields
                        size="small"
                        label="Contact"
                        name="mobile"
                        type="number"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography>Select Passout Year</Typography>
                      {years && (
                        <Select
                          fullWidth
                          options={years}
                          defaultValue={selectedYear}
                          onChange={handleYear}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <span className="innertext">Select Qualification</span>
                      {qualification && (
                        <Select
                          fullWidth
                          options={qualification}
                          defaultValue={selectedQulaification}
                          onChange={handleQualification}
                        />
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Button
                        className="button-all-clr"
                        variant="contained"
                        fullWidth
                        onClick={() => postUser(formik.values)}
                        disabled={!(formik.isValid && formik.dirty)}
                      >
                        Submit
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        variant="outlined"
                        fullWidth
                        className="button-all-clr-outlined"
                        type="reset"
                      >
                        Clear
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
