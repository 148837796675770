import React, { useState, useEffect } from "react";
import {Grid,TextField,Button,Alert,Card, CardContent} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { signup } from "../service";
import "./organism.css";
import { allqualification } from "../service";
import { allyears } from "../service";
import { InputFields } from "../molecules/InputFields";
import { Form,Formik } from "formik";


export const SignUpDirect = ({ campaign, remarks, parentsubsid }) => {
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState("");
  const [msgErr, setMsgErr] = useState("");

  const [qualification, setQualification] = useState();
  const [highestQlfc, setSelectedQualification] = useState();

  const [years, setYearlist] = useState();
  const [passoutyear, setSelYear] = useState();

  var emailRegx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  var mobileRegx = /^\d{10}$/;
  var fullnameRegx = /^[A-Za-z\s]*$/;

  const Validate = Yup.object({
    name: Yup.string()
      .min(3, "Must be 3 characters or more")
      .max(46, "Must be 16 characters or less")
      .required("Required *")
      .matches(fullnameRegx, "Invalid Name !!!!"),
    mobile: Yup.string()
      .required("Required *")
      .matches(mobileRegx, "Mobile Number should be 10 digits !!!!"),
    email: Yup.string()
      .required("Required *")
      .matches(emailRegx, "Invalid Email !!!!"),
  });

  const postUser = async (values) => {
    try {
      await signup({
        ...values,
        parentsubsid,
        remarks,
        highestQlfc: highestQlfc.value,
        passoutyear: passoutyear.value,
      });
      setMsg(
        "Thank you for your Registration !!! Please check your registered mailbox for your password details."
      );
      setMsgErr("");
      setShow(true);
    } catch (e) {
      setMsgErr(e.response.data);
      setMsg("");
    }
  };

  // const formik = useFormik({
  //   initialValues: {
  //     name: "",
  //     mobile: "",
  //     email: "",
  //   },
  //   validationSchema: validationSchema,
  //   onSubmit: (values, { resetForm }) => {
  //     try {
  //       postUser(values);
  //     } catch (e) {
  //       console.log("-e----------->", e);
  //     }
  //   },
  // });

  const handleYear = (e) => {
    setSelYear(e);
  };

  const getyears = async () => {
    let yearsdata = await allyears();
    const yearslist = yearsdata.map((item) => ({
      value: item.value,
      label: item.label,
    }));
    setYearlist(yearslist);
    setSelYear(yearslist[0]);
  };

  const handleQualification = (e) => {
    setSelectedQualification(e);
  };

  const getqualificationList = async () => {
    let qualidata = await allqualification();
    const qualis = qualidata.map((item) => ({
      value: item.value,
      label: item.label,
    }));
    setQualification(qualis);
    setSelectedQualification(qualis[0]);
  };

  useEffect(() => {
    getyears();
    getqualificationList();
  }, []);
  return (
    <Formik 
     initialValues={{
      name: "",
      mobile: "",
      email: "",
    }}
    validationSchema={Validate}
    >
      {(formik) => (
      // {show ? (
      //   <div className="addpadding10">
      //     <img src={`Images/${campaign}.png`} alt={campaign} width="100%" />
      //     <br />
      //     {msg && <Alert severity="success">{msg}</Alert>}
      //     <img src={`Images/AdvPickupBiz.jpg`} alt={campaign} width="100%" />
      //   </div>
      // ) : (
                      
              
        <Form >
          <Grid container spacing={2} sx={{ marginTop: 2, marginBottom: 2 }}>
            <Grid item xl={4} lg={4} md={3} sm={1} xs={1}></Grid>
            <Grid item xl={4} lg={4} md={6} sm={8} xs={10}>
              <Card className="shadows btn-light">
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} sm={12}>
                      <img
                        src={`Images/${campaign}.png`}
                        alt={campaign}
                        width="100%"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                      {msgErr !== "" && (
                        <Alert severity="error">{msgErr}</Alert>
                      )}
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} className="addmargin10">
                      <InputFields  
                        variant="outlined"
                        fullWidth
                        id="name"
                        name="name"
                        label="Full Name"
                        // value={formik.values.name.toUpperCase()}
                        // onChange={formik.handleChange}
                        // error={
                        //   formik.touched.name && Boolean(formik.errors.name)
                        // }
                        // helperText={formik.touched.name && formik.errors.name}
                        // autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} className="addmargin10">
                      <InputFields
                        variant="outlined"
                        fullWidth
                        id="mobile"
                        name="mobile"
                        type="number"
                        label="Phone Number"
                        // value={formik.values.mobile}
                        // onChange={formik.handleChange}
                        // error={msgErr !== ""}
                        // helperText={msgErr !== "" ? msgErr : ""}
                        // autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} className="addmargin10">
                      <InputFields
                        variant="outlined"
                        fullWidth
                        id="email"
                        name="email"
                        label="Email Id"
                        // value={formik.values.email.trim().toLowerCase()}
                        // onChange={formik.handleChange}
                        // error={msgErr !== ""}
                        // helperText={msgErr !== "" ? msgErr : ""}
                        // autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} className="addmargin10">
                      <span className="innertext">Passout Year</span>{" "}
                      {years && (
                        <Select
                          fullWidth
                          options={years}
                          defaultValue={passoutyear}
                          onChange={handleYear}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} className="addmargin10">
                      <span className="innertext">Highest Qualification</span>{" "}
                      {qualification && (
                        <Select
                          fullWidth
                          options={qualification}
                          defaultValue={highestQlfc}
                          onChange={handleQualification}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} className="addmargin10">
                      <Button
                        className="btndark"
                        variant="contained"
                        fullWidth
                        onClick={()=>postUser(formik.values)}
                      >
                        Submit
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} className="addmargin10">
                      <Button
                        className="btn-white"
                        variant="outlined"
                        fullWidth
                        sx={{ marginTop: "-25px" }}
                        type="reset"
                      >
                        Cancel
                      </Button>
                    </Grid>{" "}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={3} md={3} sm={3}></Grid>
          </Grid>
          </Form>
          )}
          {/* )} */}
    </Formik>
  );
};
