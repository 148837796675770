import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { login, getSubscriber } from "../service";
import { useDispatch } from "react-redux";
import { enumReducerType } from "../common/enumReducerTypes";
import * as Yup from "yup";
import { allbiz } from "../service";
import { allstatus } from "../service";
import { Formik } from "formik";
import { Form, ErrorMessage } from "formik";
import {
  Button,
  Card,
  CardContent,
  Grid,
  FormControl,
  IconButton,
  Typography,
  InputAdornment,
  CircularProgress,
  Alert,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./common.css";
import { InputFields } from "../molecules/InputFields";
import { Carasoules } from "./Carasoules";

export const SignIn = () => {
  const [msg, setMsg] = useState("");
  const [msgErr, setMsgErr] = useState("");
  console.log(msgErr, "---------------");
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const phonenumber =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\]?[0-9]{3}[-\s\]?[0-9]{4,6}$/im;
  const validate = Yup.object({
    mobile: Yup.string()
      .min(10, "Enter valid mobile number")
      .required("Phone number is required *")
      .matches(phonenumber, "Invalid mobile number"),
    password: Yup.string()
      .min(5, "Password must be at least 5 characters")
      .required("Password is required *"),
  });

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      setMsgErr("");
      const payload = await login(values);
      sessionStorage.setItem("token", payload.accessToken);
      dispatch({ type: enumReducerType.SET_USER_DATA, payload });
      const result = await getSubscriber(values.mobile);
      console.log("======Subs----->", result);
      dispatch({ type: enumReducerType.SUBS_DATA, payload: result });
      sessionStorage.setItem("parentsubsid", result.subsid);
      const bizdata = await allbiz();
      dispatch({ type: enumReducerType.BIZ_DATA, payload: bizdata });
      console.log("BIZDATA----->", bizdata);
      let statusdata = await allstatus();
      console.log("STATUSDATA", statusdata);
      dispatch({ type: enumReducerType.STATUS_DATA, payload: statusdata });

      setLoading(false);

      setMsgErr("");
      if (bizdata.length > 0) {
        navigate("/allusers");
      }
    } catch (e) {
      setLoading(false);
      setMsgErr(
        e.response?.data?.message || "wrong password and wrong Mobile Number"
      );
      setMsg("");
    }
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div style={{ margin: "-24px", marginTop: "-88px" }}>
      <Formik
        initialValues={{ mobile: "", password: "" }}
        validationSchema={validate}
      >
        {(formik) => (
          <Form>
            <Grid container>
              <Grid item xl={8.5} lg={8.5} md={8.5} sm={0} xs={0}>
                <div
                  style={{
                    backgroundColor: "#7F63F4",
                    width: "100%",
                    height: "100vh",
                  }}
                ></div>
              </Grid>
              <Grid item xl={3.5} lg={3.5} md={3.5} sm={12} xs={12}>
                <div style={{ padding: "50px" }}>
                  <Grid
                    container
                    spacing={3}
                    align="center"
                    sx={{ marginTop: "20px" }}
                  >
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid container>
                        <Grid item xs={8}>
                          <Typography variant="h4" color="#6A707E">
                            Login
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <InputLabel
                        sx={{
                          float: "left",
                          fontSize: "15px",
                          color: "rgb(0 0 0 / 34%)",
                        }}
                        htmlFor="my-input"
                      >
                        Phone Number*
                      </InputLabel>
                      <InputFields
                        fullWidth
                        variant="outlined"
                        name="mobile"
                        placeholder="+91 917532****"
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <InputLabel
                        sx={{
                          float: "left",
                          fontSize: "15px",
                          color: "rgb(0 0 0 / 34%)",
                        }}
                        htmlFor="my-input"
                      >
                        Password*
                      </InputLabel>

                      <FormControl fullWidth variant="outlined">
                        <InputFields
                          type={values.showPassword ? "text" : "password"}
                          name="password"
                          placeholder="●●●●●●●"
                          onChange={formik.handleChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {values.showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                    {/* <Grid
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      item
                      xl={12}
                      lg={12}
                      md={10}
                      sm={12}
                      xs={12}
                    >
                      <input type="checkbox" />
                      <InputLabel
                        sx={{
                          fontSize: "12px",
                          marginLeft: "-70px",
                          color: "rgb(0 0 0 / 34%)",
                        }}
                        htmlFor="my-input"
                      >
                        Keep me signed in
                      </InputLabel>
                      <InputLabel
                        sx={{
                          fontSize: "12px",
                          color: "rgb(0 0 0 / 34%)",
                        }}
                        htmlFor="my-input"
                      >
                        Forget Password?
                      </InputLabel>
                    </Grid> */}
                    <Grid item xl={12} lg={12} md={10} sm={12} xs={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        className="button-all-clr button-clr-width"
                        onClick={() => handleSubmit(formik.values)}
                        disabled={!(formik.isValid || loading)}
                      >
                        {loading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Login"
                        )}
                      </Button>

                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={10}
                        sm={12}
                        xs={12}
                        sx={{ marginTop: "10px" }}
                      >
                        {msgErr && <Alert severity="error">{msgErr}</Alert>}
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};
