import * as React from "react";
import { Grid } from "@mui/material";

export function NestedListBiz({ data }) {
  const [open, setOpen] = React.useState(true);
  const [show, setShow] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const handllstebtn = (indx) => {
    alert(indx);
    setShow(true);
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        className="hideshowheader"
        style={{
          fontWeight: "bold",
          backgroundColor: "#3c76d2",
          color: "white",
          padding: "5px",
          textAlign: "left",
        }}
      >
        <Grid item md={2}>
          BUSINESS
        </Grid>
        <Grid item md={2}>
          BASIC CHARG.
        </Grid>
        <Grid item md={1}>
          DISC. (%)
        </Grid>
        <Grid item md={2}>
          RECURRING
        </Grid>
        <Grid item md={2}>
          TOTAL DAYS
        </Grid>
        <Grid item md={0.6}>
          REM. 1
        </Grid>
        <Grid item md={0.6}>
          REM. 2
        </Grid>
        <Grid item md={0.6}>
          REM. 3
        </Grid>
      </Grid>

      <fieldset
        style={{
          position: "relative",
          left: "-10px",
          marginRight: "-9px",
        }}
      >
        {data.map((item, index) => (
          <Grid
            key={index}
            container
            spacing={1}
            className="gridrow"
            style={{
              textAlign: "left",
              padding: "10px",
            }}
          >
            <Grid item lg={2} md={2} sm={6} xs={6}>
              {" "}
              <span className="hideshowlbl">Business:</span> {item.name}
            </Grid>
            <Grid item lg={2} md={2} sm={6} xs={6}>
              <span className="hideshowlbl">Basic Charges:</span>{" "}
              {item.basecharges}
            </Grid>
            <Grid item lg={1} md={1} sm={6} xs={6}>
              <span className="hideshowlbl">Discount(%):</span>{" "}
              {item.discountinpercent}
            </Grid>
            <Grid item lg={2} md={2} sm={6} xs={6}>
              <span className="hideshowlbl">Recurring</span> False
              {/* {item.recurring} */}
            </Grid>
            <Grid item lg={2} md={2} sm={6} xs={6}>
              <span className="hideshowlbl">Total Days:</span> {item.totaldays}
            </Grid>
            <Grid item lg={0.6} md={0.6} sm={6} xs={6}>
              <span className="hideshowlbl">Reminder 1:</span> {item.reminder1}
            </Grid>
            <Grid item lg={0.6} md={0.6} sm={6} xs={6}>
              <span className="hideshowlbl">Reminder 2:</span> {item.reminder2}
            </Grid>
            <Grid item lg={0.6} md={0.6} sm={6} xs={6}>
              <span className="hideshowlbl">Reminder 3:</span> {item.reminder3}
            </Grid>
          </Grid>
        ))}{" "}
      </fieldset>
    </>
  );
}
