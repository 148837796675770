import React, { useEffect, useState } from "react";
import { allbiz } from "../service";
import { NestedListBiz } from "./NestedListBiz";

export const AllBiz = () => {
  const [data, setData] = useState([]);

  const getbiz = async () => {
    let bizdata = await allbiz();
    console.log("Biz======----=======>" + bizdata);

    setData(bizdata);

    console.log("Data========>" + data);
  };

  useEffect(() => {
    getbiz();
  }, []);
  return (
    <div>
      <h3>ALL BUSINESS DETAILS</h3>
      <br />
      <NestedListBiz data={data} />
    </div>
  );
};
