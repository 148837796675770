import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, TextField, Button } from "@mui/material";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import "./organism.css";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";
import "./bgimgDG.png";
import { enumReducerType } from "../common/enumReducerTypes";
import { allbiz } from "../service";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { alldashboardusers } from "../service/users/dashboard";

export const DashboardHeader = () => {
  const [type, setType] = useState("thisMonth");
  const [label, setLabel] = useState("Leads");
  const [chartType, setChartType] = useState({
    value: "bar",
    label: "Bar Chart",
  });
  const [selectedbiz, setSelectedbiz] = useState({
    value: "all",
    label: "All",
  });
  const [selectedChartlist, setSelectedChartlist] = useState({
    value: "all",
    label: "All",
  });
  const [bizlist, setBizlist] = useState([]);
  const [chartlist] = useState([
    { value: "bar", label: "Bar Chart" },
    { value: "pie", label: "Pie Chart" },
    { value: "line", label: "Line Chart" },
    { value: "doughnut", label: "Doughnut Chart" },
  ]);
  const [show, setSHow] = useState("none");

  // const bizdata = useSelector((state) => state.bizReducer.bizs);
  // const statusdata = useSelector((state) => state.statusReducer.status);
  const dispatch = useDispatch();

  // const getData = (msg) => {
  //   const bizs = bizdata.map((item) => ({ value: item._id, label: item.name }));
  //   bizs.push(selectedbiz);
  //   setBizlist(bizs);
  // };

  const getbiz = async () => {
    let bizdata = await allbiz();
    console.log("Biz======----=======>" + bizdata);
    const bizs = bizdata.map((item) => ({ value: item._id, label: item.name }));

    setBizlist(bizs);
  };

  useEffect(() => {
    getbiz();
  }, []);

  const handlesubmit = () => {
    const payload = {
      type: type,
      chartType: chartType.value,
      label,
      biz: selectedbiz.value,
    };
    dispatch({ type: enumReducerType.DASHBOARD_DATA, payload: payload });
  };

  const showhide = (e) => {
    e.target.checked ? setSHow("block") : setSHow("none");
    show === "none" ? setSHow("block") : setSHow("none");
  };

  return (
    <div>
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item md={4} xs={6} sm={6} lg={3} className="radiopanel">
            <input
              type="radio"
              name="filterduration"
              value="Today"
              onClick={() => setType("today")}
            />
            <span className="innertext">By Today</span>
          </Grid>
          <Grid item md={4} xs={6} sm={6} lg={3} className="radiopanel">
            <input
              type="radio"
              name="filterduration"
              value="Week"
              onClick={() => setType("thisWeek")}
            />
            <span className="innertext">By Week</span>
          </Grid>
          <Grid item md={4} xs={6} sm={6} lg={3} className="radiopanel">
            <input
              type="radio"
              name="filterduration"
              value="Month"
              onClick={() => setType("thismonth")}
            />
            <span className="innertext">By Month</span>
          </Grid>
          <Grid item md={4} xs={6} sm={6} lg={3} className="radiopanel">
            <input
              type="radio"
              name="filterduration"
              value="Year"
              onClick={() => setType("thisyear")}
            />
            <span className="innertext">By Year</span>
          </Grid>
          <Grid item md={6} xs={8} sm={9} lg={3} className="radiopanel">
            <input type="radio" name="filterduration" value="Year" />
            <span className="innertext">By Quarter</span>
          </Grid>
          <Grid item md={6} xs={6} sm={6} lg={4}>
            <span className="innertext">Select Biz</span>{" "}
            {bizlist && (
              <Select
                fullWidth
                options={bizlist}
                defaultValue={selectedbiz}
                onChange={setSelectedbiz}
              />
            )}
          </Grid>
          <Grid item md={6} xs={6} sm={6} lg={4}>
            <span className="innertext">Chart Type</span>
            {chartlist && (
              <Select
                options={chartlist}
                defaultValue={chartType}
                onChange={setChartType}
              />
            )}
          </Grid>
          <Grid item md={3} xs={12} sm={12} lg={3}>
            <input
              type="radio"
              name="filterduration"
              value="Dates"
              // onClick={(e) => showhide(e.target.checked)}
              onClick={showhide}
            />
            <span className="innertext">By Dates</span>
          </Grid>

          <Grid
            // container
            // spacing={2}
            item
            xs={12}
            md={12}
            sm={12}
            lg={12}
            style={{ display: show }}
          >
            <Grid item xs={11} md={11} sm={11} lg={11} className="addmargin10">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker fullWidth />
              </LocalizationProvider>
              {/* </DemoItem> */}
            </Grid>
            <Grid item xs={11} md={11} sm={11} lg={11} className="addmargin10">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker fullWidth />
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12} sm={12} lg={12}>
            <Button fullWidth variant="contained" onClick={handlesubmit}>
              Search
            </Button>
          </Grid>
        </Grid>
        {/* </Grid> */}
      </React.Fragment>
    </div>
  );
};
