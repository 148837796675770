import axios from "axios";
import { baseUrl } from "../config/common";
import { authTokenHeader } from "../config/common";

export const getAPI = async (requestUrl) => {
  const requrl = `${baseUrl}/${requestUrl}`;
  const result = await axios.get(requrl);
  return result.data;
};

export const getSecureAPI = async (requestUrl) => {
  const currentAuthTokenHeader = authTokenHeader();
  const requrl = `${baseUrl}/${requestUrl}`;
  const result = await axios.get(requrl, currentAuthTokenHeader);
  return result.data;
};

export const postAPI = async (requestUrl, payload) => {
  const requrl = `${baseUrl}/${requestUrl}`;
  const result = await axios.post(requrl, payload);
  return result.data;
};

export const postSecureAPI = async (requestUrl, payload) => {
  const currentAuthTokenHeader = authTokenHeader();
  const requrl = `${baseUrl}/${requestUrl}`;
  const result = await axios.post(requrl, payload, currentAuthTokenHeader);
  return result.data;
};
