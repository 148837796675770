import { createStore, combineReducers } from "redux";
import { allReducers } from "../Reducer";

export const configStore = () => {
  const myStore = createStore(
    combineReducers(allReducers),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  return myStore;
};
