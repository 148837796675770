import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { AllRegistrationItem } from "./AllRegistrationItem";
import { getallsubscriber } from "../service";
import { AllRegistrationItemUpdateDelete } from "./AllRegistrationItemWithUpdateDelete";

export const AllRegistrationsUpdateDelete = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [origdata, setOrigdata] = useState([]);

  const getData = async () => {
    const result = await getallsubscriber();
    console.log("=====Subscriber Details======>>", result);
    setData(result);
    setOrigdata(result);
  };

  const handleSearch = (searchtext) => {
    const filtereddata = origdata.filter(
      (item) =>
        (item.name &&
          item.name.toLowerCase().includes(searchtext.toLowerCase())) ||
        (item.mobile && item.mobile.toString().includes(searchtext)) ||
        (item.addressline &&
          item.addressline.toUpperCase().includes(searchtext.toUpperCase()))
    );
    setData(filtereddata);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    !token && navigate("/signin");
    getData();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item md={3} xs={12} sm={12} lg={2}>
        {" "}
      </Grid>{" "}
      <Grid item md={6} xs={12} sm={12} lg={7}>
        <TextField
          variant="outlined"
          label="SEARCH FOR SUBSCRIBER HERE......."
          fullWidth
          onChange={(e) => handleSearch(e.target.value)}
        />{" "}
      </Grid>{" "}
      <Grid item md={3} xs={12} sm={12} lg={4}>
        {" "}
      </Grid>
      {data.map((item) => (
        <AllRegistrationItemUpdateDelete item={item} />
      ))}
    </Grid>
  );
};
