import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Drawer,
  CssBaseline,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import BarChartIcon from "@mui/icons-material/BarChart";
import { Landing } from "../pages/Landing";
import { Navitem } from "./Navitem";
import { NavProfile } from "./NavProfile";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export const Navigation = () => {
  const sidbarData = [
    {
      BarIcon: <HomeIcon />,
      name: "Home",
      path: "/",
    },
    {
      BarIcon: <HowToRegIcon />,
      name: "Registration",
      path: "/signup",
    },
    {
      BarIcon: <SwitchAccountIcon />,
      name: "New Leads",
      clsName: "sidebar-dashIcon",
      path: "/allusers",
    },
    {
      BarIcon: <Diversity3Icon />,
      name: "All Subscribers",
      path: "/allreg",
    },
    {
      BarIcon: <BarChartIcon />,
      name: "Reports",
      path: "/reports",
    },
  ];

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const [isTokenPresent, setIsTokenPresent] = React.useState(
    !!sessionStorage.getItem("token")
  );

  React.useEffect(() => {
    const token = sessionStorage.getItem("token");
    setIsTokenPresent(!!token);

    if (!token) {
      navigate("/signin");
    }
  }, [navigate]);

  return (
    // 7058657111
    <React.Fragment>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {isTokenPresent && (
          <AppBar
            sx={{
              bgcolor: "#7F63F4",
            }}
            position="fixed"
            open={open}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                PICKUPBIZ LEARNING
              </Typography>
              <div style={{ position: "absolute", right: "10px" }}>
                {isTokenPresent && <NavProfile />}
              </div>
            </Toolbar>
          </AppBar>
        )}
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader></DrawerHeader>
          <Divider />
          <List>
            {sidbarData.map((text, index) => (
              <Navitem text={text} handleDrawerClose={handleDrawerClose} />
            ))}
          </List>
          <Divider />
        </Drawer>
        <Main sx={{ bgcolor: "white" }} open={open} onClick={handleDrawerClose}>
          <DrawerHeader />
          <Landing />
        </Main>
      </Box>
    </React.Fragment>
  );
};
