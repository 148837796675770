import React, { useState } from "react";
import { Grid, TextField, Button, Alert } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import { addrole } from "../service/roles/addrole";

export const AddRole = () => {
  const [role, setRole] = useState("");
  const [msg, setMsg] = useState("");
  const [msgErr, setMsgErr] = useState("");

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, "Must be 3 characters or more")
      .max(30, "Must be 16 characters or less")
      .required("Required *"),
  });

  const postRole = async (values) => {
    try {
      addrole(values);
      setMsg("Role Added Successfully !!!");
      setMsgErr("");
    } catch (e) {
      setMsgErr(e.response.data.message);
      setMsg("");
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      try {
        postRole(values);
        resetForm({ values: "" });
      } catch (e) {
        console.log("-e----------->", e);
      }
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Grid container spacing={2}>
          <Grid item md={3}></Grid>
          <Grid item xs={12} md={6} sm={12}>
            <TextField
              variant="outlined"
              fullWidth
              id="name"
              name="name"
              label="Add New Role"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item md={3}></Grid>

          <Grid item md={3}></Grid>
          <Grid item xs={12} md={3} sm={12}>
            <Button variant="contained" fullWidth type="submit">
              Submit
            </Button>
          </Grid>
          <Grid item xs={12} md={3} sm={12}>
            <Button variant="contained" fullWidth type="reset">
              Cancel
            </Button>
          </Grid>
        </Grid>

        <div className="addpadding10 addmargin10">
          {msg && <Alert severity="success">{msg}</Alert>}
          {msgErr && <Alert severity="error">{msgErr}</Alert>}
        </div>
      </form>
    </div>
  );
};
