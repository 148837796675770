import * as React from "react";
import { Card, CardContent, Grid } from "@mui/material";

export function NestedListTrans({ data }) {
  const [open, setOpen] = React.useState(true);
  const [show, setShow] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const handllstebtn = (indx) => {
    alert(indx);
    setShow(true);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className="hideshowheader"
        >
          <Card
            className="button-all-clr"
            sx={{
              fontWeight: "bold",
              color: "white",
              marginBottom: "10px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={2}>
                  Amount
                </Grid>
                <Grid item md={2}>
                  Trans. Type
                </Grid>
                <Grid item md={2}>
                  Payment Mode
                </Grid>
                <Grid item md={2}>
                  Currency
                </Grid>
                <Grid item md={2}>
                  Remarks
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {/* </Grid> */}
        {/* <Grid>

      <fieldset
        style={{ position: "relative", left: "-10px", marginRight: "-9px" }}
        >
        </Grid> */}
        <Grid item xs={12}>
          {data.acctransactions &&
            data.acctransactions.map((item, index) => (
              <Card
                sx={{
                  marginBottom: "10px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CardContent>
                  <Grid
                    key={index}
                    container
                    spacing={1}

                    // style={{
                    //   textAlign: "left",
                    //   padding: "10px",
                    // }}
                    // className="gridrow"
                  >
                    <Grid item lg={2} md={2} sm={6} xs={6}>
                      <span className="hideshowlbl">Amount:</span> {item.amount}
                    </Grid>
                    <Grid item lg={2} md={2} sm={6} xs={6}>
                      <span className="hideshowlbl">Transtype:</span>{" "}
                      {item.transactiontype}
                    </Grid>
                    <Grid item lg={2} md={2} sm={6} xs={6}>
                      <span className="hideshowlbl">PMT. Mode</span>{" "}
                      {item.modeofpayment}
                    </Grid>
                    <Grid item lg={2} md={2} sm={6} xs={6}>
                      <span className="hideshowlbl">Cur. Type </span>{" "}
                      {item.currencycode}
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={6}>
                      <span className="hideshowlbl">Remarks</span> {item.remark}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}{" "}
        </Grid>
        <Grid item xs={12}>
          <Card
            className="button-all-clr"
            sx={{
              fontWeight: "bold",
              color: "white",
              marginTop: "10px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <b>Credit:</b>
                  <b> {data.accsummary && data.accsummary.totalCredits}</b>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <b>Debit:</b>
                  <b> {data.accsummary && data.accsummary.totalDebits}</b>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <b>Balance:</b>
                  <b> {data.accsummary && data.accsummary.totalBalance}</b>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* </fieldset> */}
    </>
  );
}
