export const baseUrl = "https://api.pickupbiz.in/p1";

// export const baseUrl = "https://api.pickupbiz.in/";

export const authTokenHeader = () => {
  const headerAuth = {
    headers: { Authorization: `${sessionStorage.getItem("token")}` },
  };
  return headerAuth;
};

export const parentsubsid = sessionStorage.getItem("parentsubsid");
