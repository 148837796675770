import { authReducer } from "./authReducer";
import { subsReducer } from "./subsReducer";
import { userReducer } from "./userReducer";
import { bizReducer } from "./bizReducer";
import { statusReducer } from "./statusReducer";
import { dashboardReducer } from "./dashboardReducer";
import { balanceReducer } from "./balanceReducer";

export const allReducers = {
  subsReducer,
  authReducer,
  userReducer,
  bizReducer,
  statusReducer,
  dashboardReducer,
  balanceReducer
};
