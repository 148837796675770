import React from "react";
import { Routes, Route } from "react-router-dom";

import { ReportGraph } from "../molecules/ReportGraph";
import { NewRegistration } from "../organisms/NewRegistration";
import { AllRegistrations } from "../organisms/AllRegistrations";
import { SignUp } from "../organisms/SignUp";
import { SignIn } from "../organisms/SignIn";
import { AddSubscriber } from "../organisms/AddSubscriber";
import { AllRoles } from "../organisms/AllRoles";
import { AddRole } from "../organisms/AddRole";
import { Settings } from "../organisms/Settings";
import { AddBiz } from "../organisms/AddBiz";
import { AllBiz } from "../organisms/AllBiz";
import { AddCategory } from "../organisms/AddCategory";
import { AllCategory } from "../organisms/AllCategory";
import { SignOut } from "../organisms/SignOut.";
import { AddTransactions } from "../organisms/AddTransactions";
import { AllTransaction } from "../organisms/AllTransaction";
import { AllUsers } from "../organisms/AllUsers";
import { RegistrationForm } from "../organisms/RegistrationForm";
import { StatusDashboard } from "../organisms/StatusDashboard";
import { ThankYou } from "../organisms/Thankyou";
import { ReportPerStudent } from "../organisms/ReportPerStudent ";
import { ReportBizItem } from "../organisms/ReportBizItem";
import { UpdateSubscriber } from "../organisms/UpdateSubscriber";
import { AllRegistrationsUpdateDelete } from "../organisms/AllRegistrationswithUpdateDelete";
import { Home } from "../organisms/Home";

export const Landing = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/reports" element={<ReportGraph />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/logout" element={<SignOut />} />
      <Route path="/newreg" element={<NewRegistration />} />
      <Route path="/allreg" element={<AllRegistrations />} />
      <Route path="/addsubs" element={<AddSubscriber />} />
      <Route path="/allroles" element={<AllRoles />} />
      <Route path="/addroles" element={<AddRole />} />
      <Route path="/addbusiness" element={<AddBiz />} />
      <Route path="/viewbusiness" element={<AllBiz />} />
      <Route path="/setting" element={<Settings />} />
      <Route path="/addcategory" element={<AddCategory />} />
      <Route path="/allcategory" element={<AllCategory />} />
      <Route path="/addtrans" element={<AddTransactions />} />
      <Route path="/alltrans" element={<AllTransaction />} />
      <Route path="/allusers" element={<AllUsers />} />
      <Route path="/registrationform" element={<RegistrationForm />} />
      <Route path="/statusdashboard" element={<StatusDashboard />} />
      <Route path="/studentrpt" element={<ReportPerStudent />} />
      <Route path="/rptbizitem" element={<ReportBizItem />} />
      <Route path="/updatesubs" element={<UpdateSubscriber />} />
      <Route path="/allregUD" element={<AllRegistrationsUpdateDelete />} />
      <Route path="/thankyou" element={<ThankYou />} />
    </Routes>
  );
};
