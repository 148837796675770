import React,{useState} from 'react';
import { Tab,Tabs,Box } from '@mui/material';
import { UpdateBiz } from './UpdateBiz';
import { AllRollBiz } from './AllRollBiz';

const AllCompTab = () => {
    const [value, setValue] =useState('addrole');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
        <React.Fragment>

        <Box sx={{ maxWidth: { xs: 320, sm: 880 }, bgcolor: 'background.paper' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          <Tab label="Add Role" value="addrole" />
          <Tab label="Update Biz" value="updatebiz" />
        
        </Tabs>
      </Box>
      <br />
        {
            value==="addrole"&& <AllRollBiz/>
        }
        {
            value==="updatebiz"&& <UpdateBiz/>
        }
    
        </React.Fragment>
    );
};

export default AllCompTab;