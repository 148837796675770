import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid, Switch, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { VerticalBarReport } from "./graphs/VerticalBarReports";
import { PieChartReport } from "./graphs/PieChartReport";
import { LineChartReport } from "./graphs/LineChartReport";
import { DashboardHeader } from "../organisms/DashboardHeader";
import { useSelector } from "react-redux";
import "./ReportGraph.css"; // Import CSS file for styling

export const ReportGraph = () => {
  const navigate = useNavigate();
  const [chartType, setChartType] = useState("verticalBar");
  const [compData, setCompData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const selDashHeader = useSelector(
    (state) => state.dashboardReducer.dashboard
  );

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    !token && navigate("/signin");
  }, []);

  useEffect(() => {
    if (selDashHeader.chartType === "verticalBar") {
      setChartType("pie");
    } else if (selDashHeader.chartType === "pie") {
      setChartType("line");
    } else {
      setChartType("verticalBar");
    }
  }, [selDashHeader.chartType]);

  let chartComponent;

  useEffect(() => {
    console.log("Dashboard Reducer Data=Bar=>", selDashHeader.chartType);
    switch (selDashHeader.chartType) {
      case "bar":
        chartComponent = (
          <VerticalBarReport
            payloaddata={selDashHeader}
            selectAll={selectAll}
          />
        );
        break;
      case "pie":
        chartComponent = <PieChartReport payloaddata={selDashHeader} />;
        break;
      case "line":
        chartComponent = <LineChartReport payloaddata={selDashHeader} />;
        break;
      default:
        chartComponent = (
          <VerticalBarReport
            payloaddata={selDashHeader}
            selectAll={selectAll}
          />
        );
        break;
    }
    setCompData(chartComponent);
  }, [selDashHeader, selectAll]);

  return (
    <div>
      <Grid container spacing={2}>
        {/* <Grid item md={8} sm={1} xs={12} lg={9}>
          {" "}
        </Grid>
        <Grid item md={4} xs={12} sm={11} lg={3} className="userstatus">
          <Button
            fullWidth
            variant="contained"
            className="advancebtn"
            onClick={() => navigate("/StatusDashboard")}
          >
            <span> Check User Status</span>
          </Button>
        </Grid> */}
        <Grid item md={12} sm={12} xs={12} lg={12}>
          <DashboardHeader />
        </Grid>
        {/* <Grid
          item
          md={3}
          xs={12}
          sm={3}
          lg={3}
          className="userstatus addmargin10"
        >
          <Button
            fullWidth
            variant="contained"
            className="advancebtn"
            sx={{ bgcolor: "orangered" }}
            onClick={() => navigate("/StatusDashboard")}
          >
            <span> Check User Status</span>
          </Button>
        </Grid> */}
      </Grid>
      <Card className="report-card">
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              {compData}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default ReportGraph;
