import { Button, Card, CardContent, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Updaterolesofsub, allroles, getallsubscriber, rollupdatebizsofsubs } from "../service";
import PhoneIcon from "@mui/icons-material/Phone";
import { MultiSelect } from "react-multi-select-component";

export const AllRollBiz = () => {
  const [bizroll, setBizRoll] = useState([]);
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [subiddata, setSubiddata] = useState("");
  const [subsid, setSubsid] = useState("");
  const [optionroll, setOptionRoll] = useState([]);
  const roles=bizroll.map((item)=>item.value)

  const getDropsApi = async () => {
    const getSubdd = await getallsubscriber();
    setOptions(
      getSubdd.map((item) => {
        return { value: item.subsid, label: item.name };
      })
    );
    setData(getSubdd);

    const getRolldd = await allroles();
    setOptionRoll(getRolldd);
  };

  const handleRollbiz=async()=>{
    const payload={
      roles,
      subsid
    }
    const result= await Updaterolesofsub(payload)
  }

  const handleChangeAllSub = (e) => {
    setSubsid(e.value)
    const result = data.filter((i) => i.subsid.includes(e.value));
    setSubiddata(result);
  };
 
  useEffect(() => {
    getDropsApi();
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Select options={options} onChange={handleChangeAllSub} />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <MultiSelect
            options={optionroll}
            value={bizroll}
            onChange={setBizRoll}
            hasSelectAll={false}
            labelledBy="Select"
          />
        </Grid>
        {subiddata.length === 0 ? (
          <Grid textAlign="center" item xl={6} lg={6} md={6} sm={12} xs={12}>
            <h2 style={{ color: "#7B7B7B" }}>Please Select Name !!!</h2>
          </Grid>
        ) : (
          subiddata.map((item) => (
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Card>
                <CardContent>
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                      backgroundColor: item.isActive ? "green" : "red",
                      float: "right",
                    }}
                  ></div>
                  <h1> {item.name} </h1>
                  <p>
                    {" "}
                    <PhoneIcon />{" "}
                    {Number(item.mobile.toString().slice(0, -4)) + "****"}{" "}
                  </p>
                  <p> {item.email} </p>
                  <p>
                    {" "}
                    {item.addresslocation}, {item.addressline}{" "}
                  </p>
                  <p> {item.roles} </p>
                </CardContent>
              </Card>
            </Grid>
          ))
        )}
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Card>
            <CardContent>
              <ol >
         {
          bizroll.map((item)=>{
            return(
                <li> {item.label} </li>
                
                )
              }
              )
            }
            </ol>

         </CardContent>
       </Card>
</Grid>
<Grid item xs={12}>

<Button onClick={handleRollbiz} variant="contained" fullWidth>Submit</Button>
</Grid>
      </Grid>
    </div>
  );
};
