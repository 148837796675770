import { enumReducerType } from "../../common/enumReducerTypes";
export const bizReducer = (
  state = {
    bizs: [],
  },
  action
) => {
  switch (action.type) {
    case enumReducerType.BIZ_DATA:
      return {
        ...state,
        bizs: action.payload,
      };
  }

  return state;
};
