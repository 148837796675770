import React, { useEffect, useState } from "react";
import {Chart as ChartJS,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend,} from "chart.js";
import { Bar } from "react-chartjs-2";
import { isEmpty } from "../../common/reports";
import { getusersrptchart } from "../../service";

ChartJS.register(CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};

export function VerticalBarReport({ payloaddata }) {
  const [data, setData] = useState({});
  const getData = async () => {
    const resData = await getusersrptchart(payloaddata);
    setData(resData);
  };

  useEffect(() => {
    getData();
  }, [payloaddata]);

  return isEmpty(data) ? (
    <h1>Loading...</h1>
  ) : (
    <Bar options={options} data={data} />
  );
}
