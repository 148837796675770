import { enumReducerType } from "../../common/enumReducerTypes";
export const subsReducer = (
  state = {
    subsdata: { name: "PickupBiz Software Pvt Ltd" },
    currselectedsubscriber: {},
    currselectedbiz: {},
  },
  action
) => {
  switch (action.type) {
    case enumReducerType.SUBS_DATA:
      return {
        ...state,
        subsdata: action.payload,
      };
    case enumReducerType.CURR_SELECTED_SUBSCRIBER:
      return {
        ...state,
        currselectedsubscriber: action.payload,
      };
    case enumReducerType.CURR_SELECTED_BIZ:
      return {
        ...state,
        currselectedbiz: action.payload,
      };
  }

  return state;
};
