import React, { useState } from "react";
import { Grid } from "@mui/material";
import "./organism.css";

export const Report = ({ report }) => {
  //Code for getting Subscriber name with id

  return (
    <>
      {report.acctransactions &&
        report.acctransactions.map((item, index) => (
          <>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{
                textAlign: "left",
                fontWeight: "bold",
                color: "white",
                backgroundColor: "#2f2fa2",
                padding: "20px",
                textAlign: "left",
              }}
            >
              <b>Course:</b>
              {/* {report.bizname && report.bizname} */}
              {item.bizname}
            </Grid>
            {item.transactiontype === "DEBIT" && (
              <>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <span>
                    <b>Paid Date: </b>
                    <br />
                  </span>
                  {item.createdDate.toString().slice(0, 10)}
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <span>
                    <b>Mode Of Payment: </b>
                    <br />
                  </span>
                  {item.modeofpayment}
                </Grid>
                <br />

                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <span>
                    <b>Paid Amount</b>
                  </span>{" "}
                  <br />
                  Rs.{item.amount}
                </Grid>
              </>
            )}
          </>
        ))}
      <br />
      <Grid
        item
        lg={4}
        md={4}
        sm={12}
        xs={12}
        style={{
          textAlign: "left",
          fontWeight: "bold",
          color: "white",
          backgroundColor: "#2f2fa2",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <span>
          <b>Course Fees</b> <br />
        </span>{" "}
        {report.accsummary && report.accsummary.totalCredits}
      </Grid>
      <Grid
        item
        lg={4}
        md={4}
        sm={12}
        xs={12}
        style={{
          textAlign: "left",
          fontWeight: "bold",
          color: "white",
          backgroundColor: "#2f2fa2",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <b>Total Paid Amount</b>
        <br />
        Rs.{report.accsummary && report.accsummary.totalDebits}
      </Grid>
      <Grid
        item
        lg={4}
        md={4}
        sm={12}
        xs={12}
        style={{
          textAlign: "left",
          fontWeight: "bold",
          color: "white",
          backgroundColor: "#2f2fa2",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <b>Unpaid Amount</b> <br />
        Rs.{report.accsummary && report.accsummary.totalBalance}
      </Grid>
      <hr />
    </>
  );
};
