import { enumReducerType } from "../../common/enumReducerTypes";
export const balanceReducer = (
  state = {
    balance: "",
  },
  action
) => {
  switch (action.type) {
    case enumReducerType.PENDING_BALANCE:
      return {
        ...state,
        balance: action.payload,
      };
  }

  return state;
};
