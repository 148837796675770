import React, { useEffect, useState } from "react";

import { allroles } from "../service";
import { NestedListRoles } from "./NestedListRoles";

export const AllRoles = () => {
  const [data, setData] = useState([]);

  const getroles = async () => {
    const data = await allroles();
    console.log("Roles======----=======>" + data);
    setData(data);
  };

  useEffect(() => {
    getroles();
  }, []);
  return (
    <div>
      <h3>ALL ROLES DETAILS</h3>
      <NestedListRoles data={data} />
    </div>
  );
};
