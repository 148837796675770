import React, { useEffect, useState } from "react";
import { Grid, TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const NewRegistration = () => {
  const [fname, setFname] = useState("");
  const [mname, setMname] = useState("");
  const [lname, setLname] = useState("");
  const [address1, setAddress1] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [guardianMobile, setGuardianMobile] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [highestQualification, setHighestQualification] = useState("");
  const [university, setUniversity] = useState("");

  const payload = {
    fname,
    mname,
    lname,
    address1,
    city,
    pincode,
    email,
    mobile,
    guardianMobile,
    aadharNumber,
    highestQualification,
    university,
  };
  console.log("payload====>", payload);

  const navigate = useNavigate();
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    !token && navigate("/signin");
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={4} sm={6}>
        <TextField
          onChange={(e) => setFname(e.target.value)}
          variant="outlined"
          label="First Name"
          fullWidth
        />
      </Grid>
      <Grid item xs={6} md={4} sm={6}>
        <TextField
          onChange={(e) => setMname(e.target.value)}
          variant="outlined"
          label="Middle Name"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4} sm={6}>
        <TextField
          onChange={(e) => setLname(e.target.value)}
          variant="outlined"
          label="Last Name"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4} sm={6}>
        <TextField
          onChange={(e) => setAddress1(e.target.value)}
          variant="outlined"
          label="Address Line1"
          fullWidth
        />
      </Grid>
      <Grid item xs={6} md={4} sm={6}>
        <TextField
          onChange={(e) => setCity(e.target.value)}
          variant="outlined"
          label="City"
          fullWidth
        />
      </Grid>
      <Grid item xs={6} md={4} sm={6}>
        <TextField
          onChange={(e) => setPincode(e.target.value)}
          variant="outlined"
          label="Pincode"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4} sm={6}>
        <TextField
          onChange={(e) => setEmail(e.target.value)}
          variant="outlined"
          label="email"
          fullWidth
        />
      </Grid>
      <Grid item xs={6} md={4} sm={6}>
        <TextField
          onChange={(e) => setMobile(e.target.value)}
          variant="outlined"
          label="Mobile"
          fullWidth
        />
      </Grid>
      <Grid item xs={6} md={4} sm={6}>
        <TextField
          onChange={(e) => setGuardianMobile(e.target.value)}
          variant="outlined"
          label="Guardian Mobile"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4} sm={6}>
        <TextField
          onChange={(e) => setAadharNumber(e.target.value)}
          variant="outlined"
          label="Aadhar Number"
          fullWidth
        />
      </Grid>
      <Grid item xs={6} md={4} sm={6}>
        <TextField
          onChange={(e) => setHighestQualification(e.target.value)}
          variant="outlined"
          label="Highest Qualification"
          fullWidth
        />
      </Grid>
      <Grid item xs={6} md={4} sm={6}>
        <TextField
          onChange={(e) => setUniversity(e.target.value)}
          variant="outlined"
          label="University/Board"
          fullWidth
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <Button variant="contained" fullWidth>
          Submit
        </Button>
      </Grid>
      <Grid item xs={6} md={4}>
        <Button variant="contained" fullWidth>
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};
