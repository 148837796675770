import React, { useEffect, useState } from "react";
import { isEmpty } from "../../common/reports";
import { getusersrptchart } from "../../service";


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
};

export function LineChartReport({ payloaddata }) {
  const [data, setData] = useState({});
  const getData = async () => {
    const resData = await getusersrptchart(payloaddata);
    setData(resData);
  };

  useEffect(() => {
    getData();
  }, [payloaddata]);

  return isEmpty(data) ? (
    <h1>Loading...</h1>
  ) : (
    <LineChartReport options={options} data={data} />
  );
}
