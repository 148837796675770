import React from "react";
import { ErrorMessage, useField } from "formik";
import { TextField } from "@mui/material";

export const InputFields = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
      <React.Fragment>
      <TextField
        label={label}
        fullWidth
        className={`form-control shadow-none ${
          meta.touched && meta.error && "is-invalid"
        }`}
        {...field}
        {...props}
        autoComplete="off"
        error={ meta.touched && meta.error}
      />
      <ErrorMessage component="div" name={field.name} className="error" />
     
      </React.Fragment>
  );
};

