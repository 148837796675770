import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, Alert } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Checkbox from "@mui/material/Checkbox";
import {
  addtransaction,
  addsubscriber,
  allbiz,
  addressline,
  updateuserdata,
  getallsubscriber,
} from "../service";
import { enumReducerType } from "../common/enumReducerTypes";

export const UpdateSubscriber = () => {
  const parentsubsid = sessionStorage.getItem("parentsubsid");

  const [msgErr, setMsgErr] = useState("");
  const current_user = useSelector((state) => state.userReducer.currUser);
  const navigate = useNavigate();
  const [name, setName] = useState(current_user.name);
  const [id] = useState(current_user.userid);
  const [mobile, setMobile] = useState(current_user.mobile);
  const [email, setEmail] = useState(current_user.email);
  const [pincode, setPincode] = useState("");
  const [addresslocation, setAddresslocation] = useState("");
  const [addrline, setAddrline] = useState("");
  const [bizs, setBizs] = useState([]);
  const [selbizs, setSelBizs] = useState([]);
  const [msg, setMsg] = useState("");
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [origdata, setOrigdata] = useState([]);

  const handleUpdate = (currItem) => {
    const type = enumReducerType.CURR_SELECTED_SUBSCRIBER;
    const payload = currItem;
    const action = { type, payload };
    dispatch(action);
    navigate("/updatesubs");
  };
  const getData = async () => {
    const result = await getallsubscriber();
    console.log("=====Subscriber Details======>>", result);
    setData(result);
    setOrigdata(result);
  };

  const handleBizs = (item, e) => {
    const type = enumReducerType.CURR_SELECTED_SUBSCRIBER;
    const payload = item;
    const action = {
      type,
      payload,
    };
    dispatch(action);
    const selvalue = e.target.checked;
    if (selvalue) {
      setSelBizs([item, ...selbizs]);
    } else {
      const filtered = selbizs.filter((curr) => !(curr._id === item._id));
      setSelBizs(filtered);
    }
  };

  // validations

  var altmobileRegx = /^\d{10}$/;
  var emailRegx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  var pinRegx = /^\d{7}$/;
  var panRegx = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;

  const validationSchema = Yup.object({
    altmobile: Yup.string()
      .required("Required *")
      .matches(altmobileRegx, "Mobile Number should be 10 digits !!!!"),
    aadhar: Yup.string().required("Required *"),
    pan: Yup.string()
      .required("Required *")
      .matches(panRegx, "Invalid PAN Card Number !!!!"),
    addresslocation: Yup.string()
      .required("Required *")
      .min(10, "Must be 15 characters or more"),
  });

  const postSubscriber = async (values, selectedBiz) => {
    const result = await addsubscriber(values);
    setMsg("Thank you for your Subscription !!!");

    setMsgErr("");

    const filtSelBiz = bizs.filter((item) => selectedBiz.includes(item._id));
    console.log("----filtSelBiz=======--->", filtSelBiz);

    filtSelBiz.map(
      async (item) =>
        await addtransaction(
          result.subscriber.subsid,
          item._id,
          item.basecharges,
          "CREDIT",
          "ONLINE",
          "INR"
        )
    );
    navigate("/allusers");
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      altmobile: "",
      aadhar: "",
      pan: "",
      addresslocation: "",
    },

    validationSchema,
    onSubmit: (values, { resetForm }) => {
      const selectedBiz =
        selbizs.length > 0 ? selbizs.map((item) => item._id) : [];
      const reduceruservalues = {
        mobile: mobile,
        email: email,
        pincode: pincode,
        addressline: addrline,
      };
      const remainedalues = {
        name,
        addressline: addrline,
        bizs: selectedBiz,
      };
      const addnewvalues = {
        parentsubsid,
        ...reduceruservalues,
        ...values,
        ...remainedalues,
      };
      console.log("New Values", addnewvalues);
      const userPayload = {
        id,
        data: {
          username: name,
          isSubscribed: true,
        },
      };
      try {
        updateuserdata(userPayload);
        postSubscriber(addnewvalues, selectedBiz);
      } catch (e) {
        setMsgErr(e.response.data.message);
        setMsg("");
      }
    },
  });

  const reset = () => {
    setName("");
    setEmail("");
    setMobile("");
    setPincode("");
    setAddrline("");
    setSelBizs("");
  };
  const getbiz = async () => {
    const bizdata = await allbiz();
    setBizs(bizdata);
  };

  const handlePincode = async (pinnum) => {
    // var pin = /^\d{7}$/;

    if (pinnum.length === 6) {
      const addrl = await addressline(pinnum);
      setPincode(pinnum);
      setAddrline(addrl);
      setMsgErr("");
    } else {
      // setMsg("Pincode Should be 6 digits");
      setPincode(pinnum);
      setMsgErr("Invalid Pincode");

      return false;
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    !token && navigate("/signin");
    getbiz();
    getData();
  }, []);

  return (
    <div>
      {data.map((item) => {
        <>
          <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="name"
                  name="name"
                  label="Full Name"
                  defaultValue={item.name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="mobile"
                  name="mobile"
                  label="Phone Number"
                  defaultValue={item.mobile}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  defaultValue={item.email}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="altmobile"
                  name="altmobile"
                  label="Alternate Phone No."
                  defaultValue={item.altmobile}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.altmobile && Boolean(formik.errors.altmobile)
                  }
                  helperText={
                    formik.touched.altmobile && formik.errors.altmobile
                  }
                />
              </Grid>
              <Grid item xs={12} md={4} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="aadhar"
                  name="aadhar"
                  label="Aadhar Card No."
                  defaultValue={item.aadhar}
                  onChange={formik.handleChange}
                  error={formik.touched.aadhar && Boolean(formik.errors.aadhar)}
                  helperText={formik.touched.aadhar && formik.errors.aadhar}
                />
              </Grid>
              <Grid item xs={12} md={4} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="pan"
                  name="pan"
                  label="PAN Card No."
                  defaultValue={item.pan}
                  onChange={formik.handleChange}
                  error={formik.touched.pan && Boolean(formik.errors.pan)}
                  helperText={formik.touched.pan && formik.errors.pan}
                />
              </Grid>
              <Grid item xs={12} md={4} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="pincode"
                  name="pincode"
                  label="Pincode"
                  defaultValue={item.pincode}
                  onChange={(e) => handlePincode(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="addresslocation"
                  name="addresslocation"
                  label="Address"
                  defaultValue={item.addresslocation}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.addresslocation &&
                    Boolean(formik.errors.addresslocation)
                  }
                  helperText={
                    formik.touched.addresslocation &&
                    formik.errors.addresslocation
                  }
                />
              </Grid>
              <Grid item xs={12} md={4} sm={6}>
                <TextField
                  variant="outlined"
                  value={addrline}
                  label="Address Line"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>{" "}
            </Grid>
            <br />
            <h3 className="addmargin10">Select Business Details</h3>
            <Grid container spacing={2}>
              {bizs.map((item) => (
                <React.Fragment>
                  <Grid container spacing={2} className="gridrow">
                    <Grid item xs={1} md={1} sm={1} lg={1}>
                      <Checkbox
                        className="chkbox"
                        size="small"
                        value={item._id}
                        onChange={(e) => handleBizs(item, e)}
                      />
                    </Grid>
                    <Grid item xs={6} md={3} sm={5} lg={3}>
                      <b>{item.name} </b>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      md={2}
                      sm={5}
                      lg={2}
                      className="subsdetails"
                    >
                      Rs.{item.basecharges}
                    </Grid>
                    <Grid item xs={1} md={1} sm={1.03} lg={1}></Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      sm={5}
                      lg={2}
                      className="subsdetails"
                    >
                      {item.totaldays} Days
                    </Grid>

                    <Grid
                      item
                      xs={5}
                      md={3}
                      sm={5}
                      lg={2}
                      className="subsdetails"
                    >
                      {item.recurring ? "Every Month" : "One Time"}
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
            <Grid container spacing={3}>
              <Grid container spacing={2} style={{ marginTop: "12px" }}>
                <Grid item xs={12} md={12} sm={12}></Grid>
                <Grid item xs={2} md={2} sm={1}></Grid>
                <Grid item xs={4} md={4} sm={3}>
                  <Button
                    className="btndark"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={4} md={4} sm={3}>
                  <Button
                    variant="contained"
                    fullWidth
                    type="reset"
                    className="btn-light"
                    onClick={reset}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <div className="addpadding10 addmargin10">
              {msg && <Alert severity="success">{msg}</Alert>}
              {msgErr && <Alert severity="error">{msgErr}</Alert>}
            </div>
          </form>
        </>;
      })}
    </div>
  );
};
