import React, { useEffect, useState } from "react";
import { Grid, Button, Card, CardContent, InputLabel } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Checkbox from "@mui/material/Checkbox";
import { addtransaction, addsubscriber, allbiz, addressline } from "../service";
import { enumReducerType } from "../common/enumReducerTypes";
import { InputFields } from "../molecules/InputFields";
import CircularProgress from "@material-ui/core/CircularProgress";

export const AddSubscriber = () => {
  const parentsubsid = sessionStorage.getItem("parentsubsid");

  const [msgErr, setMsgErr] = useState("");
  const current_user = useSelector((state) => state.userReducer.currUser);
  const navigate = useNavigate();
  const [name, setName] = useState(current_user.name);
  const [email, setEmail] = useState(current_user.email);
  const [mobile, setMobile] = useState(current_user.mobile);
  const [pincode, setPincode] = useState("");
  const [addrline, setAddrline] = useState("");
  const [bizs, setBizs] = useState([]);
  const [selbizs, setSelBizs] = useState([]);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleBizs = (item, e) => {
    console.log("--------ITEM---->", item);
    const type = enumReducerType.CURR_SELECTED_SUBSCRIBER;
    const payload = item;
    const action = {
      type,
      payload,
    };
    dispatch(action);
    const selvalue = e.target.checked;
    if (selvalue) {
      setSelBizs([item, ...selbizs]);
    } else {
      const filtered = selbizs.filter((curr) => !(curr._id === item._id));
      setSelBizs(filtered);
    }
  };

  const phonenumber =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\]?[0-9]{3}[-\s\]?[0-9]{4,6}$/im;

  const validate = Yup.object({
    name: Yup.string()
      .min(3, "Must be 3 characters or less")
      .max(16, "Must be 16 characters or less")
      .required("Enter Your Full Name *"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    mobile: Yup.string()
      .min(10, "Enter valid mobile number")
      .required("Phone number is required *")
      .matches(phonenumber, "Invalid mobile number"),
    altmobile: Yup.string()
      .min(10, "Enter valid alt mobile number")
      .required("Phone alt number is required *")
      .matches(phonenumber, "Invalid mobile number"),
    aadhar: Yup.string()
      .matches(/^\d{12}$/, "Invalid Aadhar Number")
      .required("Aadhar Number is required"),
    pan: Yup.string()
      .matches(/^([A-Z]){5}([0-9]){4}([A-Z]){1}$/, "Invalid PAN Number")
      .required("PAN Number is required"),
    pincode: Yup.string()
      .matches(/^\d{7}$/, "Invalid PIN Code")
      .required("PIN Code is required"),
    addressline: Yup.string().required("Street Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
  });

  const postSubscriber = async (values, selectedBiz) => {
    const currBizs = selbizs.map((item) => item._id);
    console.log("====currBizs===selbizs===>", currBizs, "-----", selbizs);
    const result = await addsubscriber({
      ...values,
      bizs: currBizs,
      pincode,
      parentsubsid,
      addressline: addrline,
    });
    setMsgErr("");

    const filtSelBiz = selbizs.filter((item) => currBizs.includes(item._id));
    filtSelBiz.map(
      async (item) =>
        await addtransaction(
          result.subscriber.subsid,
          item._id,
          item.basecharges,
          "CREDIT",
          "ONLINE",
          "INR"
        )
    );
    navigate("/allreg");
  };

  const getbiz = async () => {
    const bizdata = await allbiz();
    setBizs(bizdata);
  };

  const handlePincode = async (e, formik) => {
    const pinnum = e.target.value;
    var pin = /^\d{7}$/;

    if (pinnum.length === 6) {
      setLoading(true);
      const addrl = await addressline(pinnum);
      setLoading(false);
      setPincode(pinnum);
      setAddrline(addrl);
      setMsgErr("");
    } else {
      setLoading(false);
      setMsg("Pincode Should be 6 digits");
      setPincode(pinnum);
      setMsgErr("Invalid Pincode");

      return false;
    }
    formik.handleChange(e);
  };
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    !token && navigate("/signin");
    getbiz();
  }, []);

  return (
    <Formik
      initialValues={{
        name: current_user.name,
        email: current_user.email,
        mobile: current_user.mobile,
        aadhar: "38100538611",
        pan: "BQGP3617",
        addresslocation: "P501",
        altmobile: "7058657111",
      }}
      validationSchema={validate}
    >
      {(formik) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} sm={6}>
              <InputFields
                variant="outlined"
                value={name}
                name="name"
                label="Full Name"
                type="text"
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <InputFields
                variant="outlined"
                name="mobile"
                value={mobile}
                label="Phone Number"
                type="number"
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <InputFields
                variant="outlined"
                name="altmobile"
                label="Alternate Number"
                type="number"
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <InputFields
                variant="outlined"
                value={email}
                name="email"
                label="Email"
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <InputFields
                variant="outlined"
                name="aadhar"
                label="Aadhar Card No."
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <InputFields
                variant="outlined"
                name="pan"
                label="PAN Card No."
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <InputFields
                variant="outlined"
                fullWidth
                id="pincode"
                name="pincode"
                label="Pincode"
                value={pincode}
                onChange={(e) => handlePincode(e, formik)}
                InputProps={{
                  endAdornment: loading ? <CircularProgress size={24} /> : null,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <InputFields
                variant="outlined"
                value={addrline}
                label="Address Line"
                name="addressline"
                onChange={formik.handleChange}
              />
            </Grid>{" "}
          </Grid>
          <br />
          <h3 className="addmargin10">Select Business Details</h3>
          <Grid container spacing={2}>
            {bizs.map((item) => (
              <React.Fragment>
                <Grid container spacing={2} className="gridrow">
                  <Grid item xs={1} md={1} sm={1} lg={1}>
                    <Checkbox
                      className="chkbox"
                      size="small"
                      value={item._id}
                      onChange={(e) => handleBizs(item, e)}
                    />
                  </Grid>
                  <Grid item xs={6} md={3} sm={5} lg={3}>
                    <b>{item.name} </b>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    md={2}
                    sm={5}
                    lg={2}
                    className="subsdetails"
                  >
                    Rs.{item.basecharges}
                  </Grid>
                  <Grid item xs={1} md={1} sm={1.03} lg={1}></Grid>
                  <Grid
                    item
                    xs={6}
                    md={2}
                    sm={5}
                    lg={2}
                    className="subsdetails"
                  >
                    {item.totaldays} Days
                  </Grid>

                  <Grid
                    item
                    xs={5}
                    md={3}
                    sm={5}
                    lg={2}
                    className="subsdetails"
                  >
                    {item.recurring ? "Every Month" : "One Time"}
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} sm={12}></Grid>
            <Grid item xs={8} md={8} sm={8}></Grid>
            <Grid item xs={4} md={4} sm={4}>
              <Button
                className="btndark"
                variant="contained"
                fullWidth
                onClick={() => postSubscriber(formik.values)}
              >
                Submit
              </Button>
            </Grid>
            {/* <Grid item xs={4} md={4} sm={3}>
                <Button
                  variant="contained"
                  fullWidth
                  type="reset"
                  className="btn-light"
                  onClick={reset}
                >
                  Cancel
                </Button>
              </Grid> */}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
