import { enumReducerType } from "../../common/enumReducerTypes";
export const dashboardReducer = (
  state = {
    dashboard: [],
  },
  action
) => {
  switch (action.type) {
    case enumReducerType.DASHBOARD_DATA:
      return {
        ...state,
        dashboard: action.payload,
      };
  }

  return state;
};
