import React, { useEffect, useState } from "react";
import { Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

import { UserLinkGenerator } from "./UserLinkGenerator";
import { AllRoles } from "./AllRoles";
import { AddRole } from "./AddRole";
import { AddBiz } from "./AddBiz";
import { AllBiz } from "./AllBiz";
import { AddCategory } from "./AddCategory";
import { AllCategory } from "./AllCategory";
import { AddCurrency } from "./AddCurrency";
import { AllCurrency } from "./AllCurrency";
import { AddStatus } from "./AddStatus";
import { AllStatus } from "./AllStatus";

export const Settings = () => {
  const navigate = useNavigate();
  const [compname, setcompname] = useState("");
  const handleClick = (comp) => {
    setcompname(comp);
  };
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    !token && navigate("/signin");
  });
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} sm={6} lg={2}>
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <React.Fragment>
                <Button
                  variant="contained"
                  {...bindTrigger(popupState)}
                  fullWidth
                >
                  Business
                </Button>
                <Menu {...bindMenu(popupState)}>
                  {/* <MenuItem
                    onClick={() => handleClick("AddBiz")}
                    onDoubleClick={popupState.close}
                    fullWidth
                  > */}
                  {/* onClick=
                  {() => {
                    cancelOrder();
                    handlerModal();
                  }} */}
                  <MenuItem onClick={popupState.close}>
                    <span onClick={() => handleClick("AddBiz")}>
                      Add Business
                    </span>
                  </MenuItem>
                  <MenuItem onClick={popupState.close} fullWidth>
                    <span onClick={() => handleClick("AllBiz")}>
                      Get Business Details{" "}
                    </span>
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </Grid>
        <Grid item xs={6} md={6} sm={6} lg={2}>
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <React.Fragment>
                <Button
                  variant="contained"
                  {...bindTrigger(popupState)}
                  fullWidth
                >
                  Roles
                </Button>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem onClick={popupState.close} fullWidth>
                    <span onClick={() => handleClick("AddRole")}>
                      Add Roles
                    </span>
                  </MenuItem>
                  <MenuItem onClick={popupState.close} fullWidth>
                    <span onClick={() => handleClick("AllRole")}>
                      View Roles
                    </span>
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </Grid>
        <Grid item xs={6} md={6} sm={6} lg={2}>
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <React.Fragment>
                <Button
                  variant="contained"
                  {...bindTrigger(popupState)}
                  fullWidth
                >
                  Category
                </Button>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem onClick={popupState.close} fullWidth>
                    <span onClick={() => handleClick("AddCat")}>
                      {" "}
                      Add Category
                    </span>
                  </MenuItem>
                  <MenuItem onClick={popupState.close} fullWidth>
                    <span onClick={() => handleClick("AllCat")}>
                      View Category
                    </span>
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </Grid>
        <Grid item xs={6} md={6} sm={6} lg={2}>
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <React.Fragment>
                <Button
                  variant="contained"
                  {...bindTrigger(popupState)}
                  fullWidth
                >
                  Currency
                </Button>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem onClick={popupState.close} fullWidth>
                    <span onClick={() => handleClick("AddCurr")}>
                      {" "}
                      Add Currency
                    </span>
                  </MenuItem>
                  <MenuItem onClick={popupState.close} fullWidth>
                    <span onClick={() => handleClick("AllCurr")}>
                      View Currency
                    </span>
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </Grid>
        <Grid item xs={6} md={6} sm={6} lg={2}>
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <React.Fragment>
                <Button
                  variant="contained"
                  {...bindTrigger(popupState)}
                  fullWidth
                >
                  Status
                </Button>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem onClick={popupState.close} fullWidth>
                    <span onClick={() => handleClick("AddStatus")}>
                      {" "}
                      Add Status{" "}
                    </span>
                  </MenuItem>
                  <MenuItem onClick={popupState.close} fullWidth>
                    <span onClick={() => handleClick("AllStatus")}>
                      View Status{" "}
                    </span>
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </Grid>

        <Grid item xs={12} md={12} sm={12} lg={12}>
          {compname === "AddBiz" && <AddBiz />}
          {compname === "AllBiz" && <AllBiz />}
          {compname === "AddRole" && <AddRole />}
          {compname === "AllRole" && <AllRoles />}
          {compname === "AddCat" && <AddCategory />}
          {compname === "AllCat" && <AllCategory />}
          {compname === "AddCurr" && <AddCurrency />}
          {compname === "AllCurr" && <AllCurrency />}
          {compname === "AddStatus" && <AddStatus />}
          {compname === "AllStatus" && <AllStatus />}
          {compname === "ULink" && <UserLinkGenerator />}
        </Grid>
      </Grid>
    </div>
  );
};
